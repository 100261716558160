
<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>


        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">

                    <div class="columns is-12">
                        <div class="column is-6">
                            <searchIdName table="Convenio" :label="$t('SISTEMA.CONVENIO')"  :id.sync="convenioIdAgendar">
                            </searchIdName>
                        </div>


                        <div class="column is-2">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-tooltip label="Exames que não possuem equipamentos vinculados" multilined>
                                    <b-switch v-model="examesLaboratorial" native-value="false">
                                        Exames Laboratorial?
                                    </b-switch>                        
                                </b-tooltip>
                        </div>


                            
                        <div class="column is-2">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-button type="is-primary"  icon-left="calendar" @click="confirmarAlteracaoDataAgendas()">
                                    Ir para data
                                </b-button>                                                                                  
                        </div>

                        <div class="column is-1" v-if="(this.agendas && this.agendas.length >0)">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-button type="is-success" @click="salvarAgendamentos()" icon-left="check-circle"> 
                                    {{$t('CONFIG.SALVAR')}}
                                </b-button>                                                   
                        </div>                        
                        <div class="column is-1" v-if="(this.agendas && this.agendas.length >0 && this.eventoSelecionado() )">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-button type="is-danger" @click="cancelarAgendamentos()" icon-left="close-circle"> 
                                    Cancelar
                                </b-button>                                                                                                           
                        </div>                          
                    </div>
                    <div class="columns is-12">
                        <div class="column is-12">
                            <b-field label="Agendar exames">
                                <b-taginput
                                    v-model="examesSelecionados"
                                    :data="examesLista"
                                    autocomplete
                                    :allow-new="false"
                                    :before-adding="(item) => { this.examesLista = this.exames; this.filtrosAgendamentoId=[];  return (!item.bloqueado) ? item : null  }"
                                    @input="loadTiposAgendamentos"                                    
                                    field="nome"
                                    icon="label"                                    
                                    placeholder="Selecione os exames"
                                    @typing="buscarExames">
                                    <template slot-scope="props" >
                                        <div class="media tag tag-card" :style="getStyle(props.option)">   
                                            <div class="media-left">
                                                <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                            </div>
                                            <div class="media-content">
                                                {{ props.option.nome }}
                                                <span v-if="props.option.bloqueado">
                                                    <br />
                                                    <span class="tag">{{$t('GUIAATENDIMENTO.BLOQUEADO')}}{{props.option.motivoBloqueio}}</span>
                                                </span>                                                
                                            </div>                           
                                        </div>
                                    </template>
                                </b-taginput>
                            </b-field>
                        </div>
                                       

                    </div>                    
                    <div class="columns is-multiline is-mobile is-gapless">
                        <b-checkbox-button class="column is-2-desktop is-half-mobile" v-for="(tipo) in tiposAgendamentoAtivos" v-bind:key="tipo.id" 
                                v-model="filtrosAgendamentoId"
                                :native-value="tipo.id">
                            <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>           
                            <span>{{tipo.nome}}</span>
                        </b-checkbox-button>
                    </div>
                </article>

                <div v-show="!isLoading" class="columns whitebox"> 
                    <div style="border-right: 3px #ced8ce solid;" class="column is-fullwidth-mobile p-0" v-for="(agenda, index) in agendas" v-bind:key="index">
                        <b-collapse  aria-id="agendaDias">
                            <template slot="header">
                                <span>  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading">                                                                               
                                <div class="column is-12 has-text-centered whitebox" > 
                                        <b-button class="is-pulled-left "  icon-left="chevron-triple-left" @click="proximoDiaVago(-1,agenda);" ></b-button>                                                                            
                                        <b-button class="is-pulled-left"  icon-left="chevron-left" @click="proximoDia(-1,agenda);" ></b-button>        
                                        
                                        <a href="#" class="has-text-grey" title="Alterar Data da Agenda" @click="confirmarAlteracaoDataAgendas(agenda);">
                                            <b-icon icon="calendar" size="is-small"></b-icon>
                                        </a>                                                                      
                                        <span class="is-size is-inline-block mt-2"><b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b></span>                                     

                                        <b-button class="is-pulled-right "  icon-left="chevron-triple-right" @click="proximoDiaVago(1,agenda);" ></b-button>                                    
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="proximoDia(1,agenda);" ></b-button> 
                                </div>                                 
                                <agendarnovo-item v-if="agenda.eventosDia.length>0" :eventos="agenda.eventosDia" @incluirNovoHorarioDia="incluirNovoHorarioDia" 
                                     @loadDataDia="loadDataDia"  @verificarAgenda="verificarAgenda"  :exibirCheckBox="(agenda.possuiEquipamentoVinculado && agendas.length >0 )"></agendarnovo-item>                                  
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>            
        </div>
       
    </section>
</template>

<style scoped>
    .tag-card{
        height: auto;
        background-color: unset;
        padding: unset;
        align-items: flex-start;
    }

      
</style>

<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';
    import moment from 'moment';    
    import agendarnovoItem from '@/components/atendimento/agendanovo-item.vue';
    import searchIdName from '@/components/searchIdName.vue';
    import modalAlterarDataAgendamento from '@/components/atendimento/modalAlterarDataAgendamento.vue'
        
 
    export default {
        components: {
            titlebar,
            agendarnovoItem,
            searchIdName,
            
        },

        computed: {
            ...mapState([                
                'config',
                'unidadeId',
                'componentes'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                ]
            }
        },
        watch: {
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadDataDia();                                                
            },
            filtrosAgendamentoId: function(value) {
                if(value && value.length > 0){
                    this.loadDataDia()
                }else{
                    this.agendas = [];
                }
                this.eventosSalvar = [];
            },            
            examesLaboratorial: function() {
                this.examesSelecionados = [];
                this.filtrosAgendamentoId = [];
                this.eventosSalvar = [];
                this.loadTiposAgendamentos();
            },            
            exames: function() {
                this.examesLista = this.exames
            },
        },        
        data() {
            return {                                                     
                filtrosAgendamentoId: [],
                tiposAgendamentoAtivos: [],
                agendas: [],
                exames: [],            
                examesSelecionados: [],
                examesLista: this.exames,
                isLoading: false,
                examesLaboratorial: false,
                eventosSalvar:[],
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },             
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                convenioIdAgendar: null,
            }
        },
        created() {
            return true;
        },
        mounted() {        
            this.componentes.sidebarIsOpened = false;            
            this.loadTiposAgendamentos();
        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },        
        methods: {

            confirmarAlteracaoDataAgendas(agenda){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataAgendamento,
                    events: {
                        alteracaoDataAgendas: this.alteracaoDataAgendas
                    }, 
                    props: {
                        agenda:agenda                        
                    },                                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            }, 
            
            eventoSelecionado(){
                const eventosSelecionados = [];

                // Filtrar os eventos selecionados
                this.agendas.forEach(a => {
                        const agendaSelecionada = a.eventosDia.filter(x=>x.selecionado)[0]; 
                        
                        if(agendaSelecionada){                            
                            eventosSelecionados.push(agendaSelecionada);                 
                        }
                });

                return (eventosSelecionados.length > 0);
            },            
            cancelarAgendamentos(){                
                    this.agendas.forEach(a => {                                            
                        a.eventosDia.forEach(a => {                                                                   
                            a.bloquearAgenda = false;
                            a.selecionado = false; 
                            a.descricaoExame="";                       
                        });                        
                    });
                    
                    this.eventosSalvar = [];
            },
            verificarAgenda(evento){

                if(this.examesSelecionados.length<=0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Nenhum exame selecionado",
                        type: 'is-warning',
                        queue: false
                    });
                    evento.selecionado = false;
                    return true;
                }

                this.isLoading = true;
                
                this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(a => {
                                   
                        const equipments = a.eventosDia[0].equipments;                         
                        const testCodes = [];

                        //Em cada agenda pode ter N equipamentos
                        //Adiciona todos testCodes feitos pela Agenda                        
                        equipments.forEach(q => {
                            q.testCodeConfig.forEach(t => {                                
                                testCodes.push(t);
                            });
                            
                        });

                        //Se a agenda selecionada permitir agendar todos exames selecionados
                        if(this.examesSelecionados.map(e => parseInt(e.id))
                            .every(e => testCodes.map(e => parseInt(e.lisTestCode)).includes(e))==true){                            

                            //verificar se algum exame nao ja agendado
                            const examesIds = [];                            
                            this.examesSelecionados.map(e => parseInt(e.id)).forEach(exameId=>{  
                                let NaoAgendado = true;                          
                                this.eventosSalvar.forEach(evento => {                                    
                                    if(evento.examesIds.filter( ex => ex == exameId).length>0)
                                        NaoAgendado = false;
                                })

                                if(NaoAgendado)
                                    examesIds.push(exameId);

                            });
                            
                            this.eventosSalvar.push({
                                examesIds: examesIds,
                                evento: evento
                            })
                            
                            //Como ja encontrou agenda para todos exames,bloquear tudo 
                            this.bloquearAgenda();
                            
                            evento.descricaoExame += this.examesSelecionados.filter(x=> examesIds.includes(x.id) ).map(e => e.nome).join(" | ");                            

                        }else{                            
                            this.examesSelecionados.forEach(e => { 
                                if(testCodes.filter(t=>t.lisTestCode == e.id).length>0){
                                    this.eventosSalvar.push({
                                        examesIds: [e.id],
                                        evento: evento
                                    });  
                                    
                                    //Bloquear Apenas a agenda atual   
                                    this.bloquearAgenda(a);                                                                           
                                    
                                    evento.descricaoExame += this.examesSelecionados.filter(x=>x.id==e.id).map(e => e.nome).join(" | ");                            
                                           
                                }

                            });  
                            

                            if(this.examesSelecionados.length == this.eventosSalvar.length ){
                                //Como ja encontrou agenda para todos exames,bloquear tudo 
                               this.bloquearAgenda();
                            }                            
                        }

                                                                                        
                });
                
                this.isLoading = false;
                                
            },     
            bloquearAgenda(agenda){
                //Se passar agenda bloquear apenas
                if(agenda){
                    agenda.eventosDia.forEach(a => {                                                                   
                        a.bloquearAgenda = true;                        
                    }); 
                // bloquear todas abertas                     
                }else{
                    this.agendas.forEach(a => {                                            
                        a.eventosDia.forEach(a => {                                                                   
                            a.bloquearAgenda = true;                        
                        });                        
                    });
                }
                

            },
            alteracaoDataAgendas(novaData,agenda){
                if(agenda){
                    agenda.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));               
                    agenda.proximo = 0; 

                    this.carregarDataDia(agenda);
                    
                }else{
                    this.agendas.forEach(a => {
                        a.proximo = 0; 
                        a.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));     
                        this.carregarDataDia(a);    
                    }); 

                    
                }
               
                
            },                                
            getStyle(exame) {                
                return (exame.bloqueado) ? ' border: 1px solid red; '  : ' '; 
            },                    
            buscarExames(text) {

                const params = [];

                params.push(`nome=${text}`);
                if (this.convenioIdAgendar) params.push(`convenioId=${this.convenioIdAgendar}`);

                this.$http.get(`/Search/Exame?${params.join('&')}`)
                .then(({ data }) => {
                    this.exames = [];
                    data.forEach((item) => this.exames.push(item))
                })
                .catch((error) => {
                    this.exames = []
                    throw error
                })
                .finally(() => {
                    this.isFetching = false
                })

            },
            
            proximoDia(dia,agenda){                              
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);                                                
                agenda.proximo = dia;                 
                this.carregarDataDia(agenda);                                                               
            }, 
            proximoDiaVago(dia,agenda){                                   
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);
                agenda.proximo = dia;                                
                this.carregarDataDia(agenda);                                
            },                  
            carregarDataDia(agenda){ 
                
                this.isLoading = true;                
                const params = [];
                if (agenda.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(agenda.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (agenda.tipoAgendamentoId) params.push(`tipoAgendamentoId=${agenda.tipoAgendamentoId}`);                

                this.$http.get(`/api/agenda/AgendarDia?${params.join('&')}`)
                .then(res => res.data)
                .then(data => {
                    agenda.eventosDia = data?.eventos                                    
                        .map(d => {
                            return this.getEvento(d);
                        });

                    this.isLoading = false;    
                    this.$forceUpdate();                          
                })
                .finally(() => {                                    
                    if(agenda.eventosDia.length==0){
                        if(agenda.proximo>0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + 1); 
                            this.carregarDataDia(agenda);
                        }else if (agenda.proximo<0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() - 1); 
                            this.carregarDataDia(agenda);
                        }                                                                                
                        return;                                
                    }        
                });                
                                
            },      

            loadDataDia(){
                const params = [];
                              
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/Agendar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {    

                                data.forEach((a, index) => {
                                    a.eventosDia = a.eventosDia.map(d => { return this.getEvento(d); });
                                    a.dataDiaFiltro =  new Date(this.$moment(a.dataDiaFiltro).toDate().setHours(0,0,0,0));
                                });                                
                                
                                this.agendas = data;

                            }                            
                        }).catch(e => {
                            throw e;
                        }).finally(() => {                                    
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },                                              
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },   
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora),
                    selecionado: false,
                    bloquearAgenda: false,
                    descricaoExame: ""
                }
            },
            loadTiposAgendamentos(){
                const params = [];
                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/tiposAgendamentoAtivos?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {                            
                            if(this.examesLaboratorial){
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length==0);                            
                            }else{
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length>0);                             
                            }
                            
                            this.agendas=null;
                                              
                        }).catch(e => {
                            throw e;
                        }).finally(() => {                                    
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },            
           
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            },

            salvarAgendamentos(){
                

                if(this.eventosSalvar<=0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Nenhum exame selecionado",
                        type: 'is-warning',
                        queue: false
                    });
                    return true;
                }

                if(this.eventosSalvar.length>0){
                    
                    this.eventosSalvar.forEach(x => {                        
                        this.incluirNovoHorarioDia(x.evento,x.evento.dataHora);
                    });

                    this.$router.push({ name: 'pacientes', params: {procurar: null, criarGuia: true, 
                    agendamento: this.eventosSalvar, convenioIdAgendar: this.convenioIdAgendar, agendamentoDashboard:false } })
                    
                }else{

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Nenhum evento selecionado",
                        type: 'is-warning',
                        queue: false
                    });

                }
                
            },            
            incluirNovoHorarioDia(evento, horario,bloqueio){      
                const params = {};
                
                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId = evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario = moment(horario).toDate(); 

                if(bloqueio){
                    this.adicionarAgendamento(evento);
                }

                //Novo paciente redirecionamento listagem paciente
                if(evento.equipments.length > 0) {
                    this.adicionarAgendamento(evento, true);                
                } //Novo paciente Padrão
                else{                                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })  

                }                  
            },
            async adicionarAgendamento(evento, equip) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                evento.tipoAgendamentoId == null 
                    ? params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`)
                    : params.push(`tipoAgendamentoId=${evento.tipoAgendamentoId}`) 

                evento.pacienteId == null 
                    ? params.push(`pacienteId=${evento.paciente.id}`) 
                    : params.push(`pacienteId=${evento.pacienteId}`)


                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);

                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);

                params.push(`observacao=${evento.observacao ? evento.observacao : 'Reservado'}`);

                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                if (this.id && evento.observacao!='Bloqueio' ) params.push(`guiaId=${this.id}`);
                
                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if(equip) {                            
                            evento.id = data.id;                            
                        }
                        else if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    });   

                   
                    this.loadDataDia();
                    
                                            
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },                      
        }
    }
</script>