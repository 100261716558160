<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REPASSEMEDICOCADASTRO.NOME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REPASSEMEDICOCADASTRO.NOME')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REPASSEMEDICOCADASTRO.VALORMINIMO')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REPASSEMEDICOCADASTRO.VALORMINIMO')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="number" v-model="model.valorMinimo" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REPASSEMEDICOCADASTRO.PORCENTAGEM')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REPASSEMEDICOCADASTRO.PORCENTAGEM')"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="number" v-model="model.porcentagem" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="column is-12">
                        <searchIdName :id.sync="model.medico.id" :nome.sync="model.medico.nome" table="Medico"
                            :label="$t('REPASSEMEDICOCADASTRO.MEDICO')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :id.sync="model.convenio.id" table="Convenio" 
                            :label="$t('REPASSEMEDICOCADASTRO.CONVENIO')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :id.sync="model.setor.id" table="Setor" 
                            :label="$t('REPASSEMEDICOCADASTRO.SETOR')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :id.sync="model.tabelaPreco.id" table="TabelaPreco" 
                            :label="$t('REPASSEMEDICOCADASTRO.TABELADEEXAMES')" />
                    </div>
                </article>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                v-if="isInRole('repassemedico-alterar')"
                                native-type="button"
                                icon-left="check-circle"
                                :loading="loading"
                                @click="onSubmit()">
                        {{$t('EXAMECADASTRO.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                         {{$t('EXAMECADASTRO.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue';

    export default {
        data() {
            return {
                model: {
                    nome: null,
                    valorMinimo: null,
                    porcentagem: null,
                    medico: {
                        id: null,
                        nome: null,
                    },
                    convenio: {
                        id: null
                    },
                    setor: {
                        id: null
                    },
                    tabelaPreco: {
                        id: null
                    }
                },
                loading: false
            }
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
            this.$http.get('/api/manutencao/repassemedico?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;

                    if(!this.model.convenio || !this.model.setor || !this.model.tabelaPreco){

                        if(!this.model.convenio){
                            this.model.convenio = {
                                id: null
                            }
                        }
                        
                        if(!this.model.setor){
                            this.model.setor = {
                                id: null
                            }
                        }
                        
                        if(!this.model.tabelaPreco){
                            this.model.tabelaPreco = {
                                id: null
                            }
                        }
                    }

                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REPASSEMEDICOCADASTRO.MANUTENCAO'),
                    this.$t('REPASSEMEDICOCADASTRO.REPASSEMEDICO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.loading = true;

                if(!this.model.nome){
                    this.$buefy.toast.open({
                        duration: 3000,
                        message: "Insira o nome do Repasse Médico!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.valorMinimo){
                    this.$buefy.toast.open({
                        message: "Insira o Valor Mínimo!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.porcentagem){
                    this.$buefy.toast.open({
                        message: "Insira a Porcentagem!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }

                else if(!this.model.medico.id){
                    this.$buefy.toast.open({
                        message: "Insira o ID do Médico!",
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.convenio.id || !this.model.setor.id || !this.model.tabelaPreco.id){
                    if(!this.model.convenio.id)
                        delete this.model.convenio
                    if(!this.model.setor.id)
                        delete this.model.setor
                    if(!this.model.tabelaPreco.id)
                        delete this.model.tabelaPreco
                }

                this.$http.post('/api/manutencao/RepasseMedico', this.model)
                    .then(() => {
                        this.$route.params.id ? this.$router.back() : this.$router.push({ name: 'repassesmedicos' })
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('REPASSEMEDICOCADASTRO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                        
                        this.loading = false;
                    })
            }
        }
    }
</script>