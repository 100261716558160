export default {
    LANGUAGE:{
        NAME:"Portugues"
    },
    SISTEMA:{
        ENVIAR: "Enviar",
        SOLICITANTE: "Solicitante",
        EXECUTANTE: "Executante",
        CONFIRMAR: "Confirmar",
        CONVENIO: "Convênio",
        PACIENTE: "Paciente",
        UNIDADE: "Unidade",
        FORNECEDOR: "Fornecedor",
        CENTROCUSTO: "Centro de custo",
        SOMENTELEITURA: "Somente leitura",
        NOVO: "Novo",        
        SELECIONEDATAHORA: "Selecione a data e hora",
        ATENCAO: "Atenção!"
    },
    GREETINGS: {
        BOMDIA: "Bom dia!",
        BOATARDE: "Boa tarde!",
        BOANOITE: "Boa noite!",
    },
    PORTAL:{
        SAUDACOES:"Olá,",
        INSTRUCOES: "Por esta página você poderá acompanhar todos os exames solicitados neste laboratório.",
        PESQUISA: "Para melhorar nossos serviços, pedimos a gentileza de responder as seguintes pesquisas",
        ANALISE: "Análises clínicas",
        RADIOLOGIA: "Radiologia",
        NUMEROACESSO: "Número de acesso",
        VIEWER: "Viewer",
        DATAEMISSAO: "Data de emissão",
        RESULTADO: "Resultado",

    },
    GUIAS:{
        DATAEMISSAO: "Data de emissão",
        NUMEROGUIA: "Número da Guia",
        PACIENTENOME: "Nome do Paciente",
        FILTROS: "Filtros",
        GUIA: "Guia",
        RETORNO: "Retorno",
        RACAESPECIE: "Raça/Espécie",
        DATASOLICITACAO: "Data de solicitação",
        DATAENTREGA: "Data de entrega",
        DATAPREVISTA: "Data prevista",
        PRECO: "Preço",
        AUTORIZACAO: "Sem autorização do convênio!",
        CONTATO: "Por favor, entre em contato com o laboratório.", 
        ABRIRRESULTADOS: "Abrir resultados",
        EDITAR: "Editar guia",
        ETIQUETA: "Imprimir etiqueta",
        COMPROVANTE: "Imprimir recibo",
        ENVIAEMAIL: "Enviar por email",
        VERMAIS: "Clique para ver mais informações de cada exame",
        PRONTOS: "Exames prontos",
        SEMRESULTADO: "Não foram encontrado exames para os filtros selecionados!",
        REFERENCIA: "Referência",
        RESPONSAVEL: "Responsável",
    },
    PESQUISASATISFACAO:{
        TITULOPORTAL: "Portal",
        TITULOPESQUISA: "Pesquisa de Satisfação",
        SALVAR: "Salvar",
        VOLTAR: "Voltar",
    },
    FILTROS:{
        FILTRO: "Filtros",
        PERIODO: "Período",
        PROCURAR: "Procurar por nome/Data de nascimento/Id da guia/Referência da guia",
        INTEGRADORES: "Integradores",
        INTEGRADOR: "Integrador",
        LOCAL: "Local",
        TODOS: "Todos",
        PARCIAIS: "Parciais",
        PRONTOS: "Prontos",
        ATRASADOS: "Atrasados",
        RECOLETA: "Recoleta",
        FILTRAR: "Filtrar por",
        DATASOLICITACAO: "Data solicitação",
        DATARETORNO: "Data retorno",
        DATAASSINATURA: "Data assinatura",
        RESULTADOIMPRESSO: "Somente resultados não impressos",
        EXAMEPORTAL: "Somente exames do portal",
        ORDEM: "Ordenar por",
        DATAEMISSAO: "Data de emissão",
        NUMEROGUIA: "Numero da guia",
        PACIENTENOME: "Nome do Paciente",
        MODIFICACAO: "Última modificação",
        SITUACAO: "Situação",
        SITUACAOINFO: "Situação Parciais e Prontos são definidos a partir dos exames digitados na guia",
        PEDIDOSLOTE: "Pedidos sem lote",
        LOTESENVIADO: "Lotes enviados",
        LOTENAORECEBIDO: "Lotes enviados e não recebidos",
        LOTERECEBIDO: "Lotes recebidos",
        AMOSTRAIMPRESSA: "Amostras não impressas",
        BUSCAR: "BUSCAR",
        CANCELAR: "CANCELAR"
    },
    ORCAMENTO:{
        EXAMES: "Exames",
        EXAMESENCONTRADOS: "Nenhum exame encontrado",
        EXAME: "Exame",
        APELIDO: "Apelido",
        NOME: "Nome",
        PRECO: "Preço",
        TOTALEXAME: "Total de exames",
        BLOQUEADO: "Bloqueado",
    },
    PACIENTESCONVENIO:{
        ID: "Id",
        BUSCARPORNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        NOME: "Nome",
        SEXO: "SEXO",
        NASCIMENTO: "Nascimento",
        INSCRICAO: "Inscrição",
        CPF: "CPF"
    },
    LOTES:{
        CODIGOBARRAS: "Código de barras",
        INCLUIR: "Incluir",
        INCLUIRPENDENCIA: "Incluir pendente",
        CONTADOR: "Contador",
        AMOSTRA: "Amostra",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        EXAMES: "Exames",
        TEMPERATURA: "Temperatura",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        AMOSTRASELECIONADA: "Amostra já selecionada",
        AMOSTRAJAINCLUIDA: "A amostra {msg} ja está incluída.",
    },
    CATALOGOEXAMES:{
        APELIDONOME: "Procurar por apelido/nome",
        CODIGO: "Código",
        ID: "Id",
        NOME: "Nome",
        APELIDO: "Apelido",
        VISUALIZAR: "Visualizar",
    },
    AMOSTRAS: {
        SELECIONARAMOSTRA: "Selecionar/deselecionar todas amostras",
        IMPRIMIRAMOSTRA: "Imprimir amostra",
        FILTROS: "Filtros",
        AMOSTRASPERIODO: "Amostras no período de "
    },
    AMOSTRA: {
        AMOSTRACANCELADA: "Amostra cancelada",
        AMOSTRAEXAMECANCELADA: "Amostra com todos os exames cancelados",
        AMOSTRAINCIDENCIA: "Amostra com incidência",
        CODIGOBARRAS: "Código de barras",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        CODIGOETIQUETA: "Etiqueta pré-coleta",
        CODIGOINTEGRACAO: "Etiqueta integração",
        LOTE: "Lote",
        CONGELADO: "Acondicionamento: Congelado",
        REFRIGERADA: "Acondicionamento: Refrigerado",
        AMBIENTE: "Acondicionamento: Ambiente",
        ETIQUETA: "Acondicionamento: Etiqueta",
        EXAMEASSINADO: "Exame assinado",
        EXAMEDIGITADO: "Exame digitado",
        EXAMECANCELADO: "Exame cancelado",
        IDENTIFICACAO: "Identificação"
    },
    RELACAOEXAME: {
        SOLICITADO: "Solicitado em",
        TOTAIS: "Totais",
        GUIAS: "Guias",
        PACIENTES: "Pacientes e",
        EXAMES: "Exames",
        EXAMESPERIODO: "Exames solicitados no período de "
    },
    VISUALIZAREXAMES:{
        APELIDO: "Apelido",
        NOME: "Nome",
        QTDEAMOSTRAS: "Qtde. amostras", 
        PRAZODIA: "Prazo dias",
        PRAZOHORA: "Prazo horas",
        PRAZOMINUTO: "Prazo minutos",
        TEMPOJEJUM:  "Tempo mínimo de jejum",
        VOLUMEMINIMO: "Volume mínimo",
        VOLUMEMINIMODESEJADO: "Volume desejado",
        MEDICAMENTO: "Medicamento",
        DUM:"Data da última menstruação",
        PESO: "Peso",
        ALTURA: "Altura",
        MASCARAS: "Visualizar máscaras",
        VERSÃO: "Versão",
        SINONIMIA: "Sinonímia",
        RECIPIENTES: "Recipientes",
        OBTERAMOSTRA: "Obtenção de amostra",
        INFOLABORATORIO: "Informações ao laboratório",
        INFOPACIENTE: "Informações ao paciente",
        APLICACAOOCLINICA: "Aplicação clínica / interpretação",
        CRITREJEICAO: "Critérios de rejeição",
        TABELA: "Tabela de preço",
        BLOQUEADO: "Bloqueado",
        NEGOCIADO: "Negociado",
        VOLTAR: "Voltar",
        CODIGO: "Código",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        ESTABILIDADEAMBIENTE: "Estabilidade ambiente (h)",
        ESTABILIDADEREFRIGERADO: "Estabilidade refrigerado (h)",
        ESTABILIDADECONGELADO: "Estabilidade congelado (h)",
    },
    RESULTADOS: {
        IMPRIMIRRESULTADO: "Imprimir resultados",
        GUIA: "Guia",
        REFERENCIA: "Referência",
        EMISSAO: "Emissão",
        PACIENTEID: "Paciente Id",
        PACIENTENOME: "Paciente nome",
        LOCAL: "Local",
        LIBERACAO: "Liberação",
        LIBERACAOINFO: "Liberação é definida a partir de exames assinados na guia",
        NENHUM: "Nenhum",
        PARCIAL: "Parcial",
        TOTAL: "Total",
        RESULTADOSPERIODO: "Guias com resultados emitidos no período de "
    },
    AVISOS: {
        PEDIDO: "Pedido", 
        AMOSTRA: "Amostra",
        TIPO: "Tipo",
        EXAMES: "Exames",
    },
    RECOLETAS: {
       PEDIDO: "Pedido", 
       CODIGOBARRAS: "Código de barras",
       SOLICITADA: "Solicitada em",
       MOTIVO: "Motivo",
       EXAMES: "Exames",
       ETIQUETA: "Imprimir etiqueta",
    },
    PORTALESTOQUE: {
        EDITAR: "Editar",
        CODIGO: "Código",
        REFERENCIA: "Referência",
        DATA: "Data",
        USUARIO: "Usuário",
        STATUS: "Status",
        INCLUIR: "Incluir",
        PESQUISAR: "Pesquisar por Referência ou Data",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        NOME: "Nome",
        TITULO: "Cadastro",
        SALVAR: "Salvar",
        RECEBER: "Receber",
        CANCELAR: "Cancelar",
        SALVANDO: "Salvando...",
        SITUACAO: "Situação do pedido",
        SELECIONELOCAL: "Selecione o local",
        LOCAL: "Local",
        ESTOQUE: "Estoque",
        SELECIONEESTOQUE: "Selecione o estoque",
        ITENS: "Itens do pedido",
        ITENSOBRIGATORIOS: "Insira pelo menos 1 item ao pedido",
        QUANTIDADESOBRIGATORIAS: "Todos os itens devem ter uma quantidade válida"
    },
    NAVBARCONVENIO: {
        GUIAS: "Guias",
        ORCAMENTO: "Orçamento",
        INCLUIRGUIA: "Incluir guia",
        ENVIARLOTE: "Enviar lote",
        CATALOGO: "Catálogo",
        DEEXAMES: "de exames",
        AMOSTRAS: "Amostras",
        EXAMES: "Exames",
        ESTOQUE: "Estoque",
        RESULTADOS: "Resultados",
        AVISOS: "Avisos",
        RECOLETA: "Recoleta",
        REMARCACAO: "Remarcação",
        VALORESCRIT: "Valores críticos",
        INTEGRACAO: "Integrações",
        FATURAS: "Faturas"
    },
    VALORCRITICO: {
        PEDIDO: "Pedido",
        EXAMES: "Exames",
    },
    GUIA:{
        INDENTIFICACAO: "Identificação",
        REFERENCIA: "Referência",
        LOCALORIGEM: "Local de origem",
        SELECIONELOCAL: "Selecione o local de origem",
        EMITIDA: "Emitida em",
        HORASJEJUM: "Horas de jejum",
        CALCULARHORASJEJUM: "Clique para calcular as horas de jejum | Ultima refeição há 1 hora | Ultima refeição há {n} horas",
        INSCRICAO: "Inscrição",
        SEXO: "SEXO",
        NASCIMENTO: "Nascimento",
        CBO: "CBO-S",
        EXAMES: "Exames",
        AMOSTRAS: "Amostras",
        INCLUIREXAME: "Incluir exame",
        PROCURAR: "Procurar por",
        APELIDO: "Apelido",
        NOME: "Nome",
        CÓDIGO: "Código externo",
        IDENTIFICACAO: "Identificação",
        NENHUMEXAME: "Nenhum exame disponível.",
        BLOQUEADO: "BLOQUEADO",
        JEJUM: "",
        CANCELARSELECIONADOS: "Cancelar exames selecionados",
        INCLUIR: "Incluir",
        IMPRIMIRCOMPROVANTE: "Imprimir comprovante",
        COMPROVANTE: "Comprovante",
        OBSERVACOES: "Observações",
        HIPOTESE: "Hipótese diagnóstica",
        CID10: "CID10",
        INDICACAO: "Indicação clínica",
        MEDICAMENTOS: "Medicamentos",
        INCLUIRMEDICAMENTO: "Inclua um medicamento",
        RESPOSTAS: "Respostas",
        NENHUMARESPOSTA: "Não existe nenhuma resposta",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MEDICOOBRIGATORIO: "Solicitante obrigatório",
        MESSAGEMEDICO: "Especifique um solicitante para continuar.",
        LOCALOBRIGATORIO: "Local de origem obrigatório",
        MENSAGEMLOCAL: "Especifique um lugar de origem para continuar.",
        EXAMEOBRIGATORIO: "Exames obrigatórios",
        MENSAGEMEXAME: "A guia precisa ter pelo menos 1 exame.",
        MEDICAMENTOOBRIGATORIO: "Medicamento obrigatório",
        MENSAGEMMEDICAMENTO: "Estes exames precisam que informe os <b>medicamentos</b> em uso.",
        PESOOBRIGATORIO: "Peso obrigatório",
        MENSAGEMPESO: "Confirma a inclusão do peso do paciente ?",
        ALTURAOBRIGATORIA: "Altura obrigatória",
        MENSAGEMALTURA: "Confirma a inclusão da altura do paciente ?",
        CONFIRMA: "Confirmar",
        EXAMEINCLUIDO: "Exame já incluído",
        MENSAGEMINCLUIDO: "Confirma a inclusão do exame: ",
        BLOQUEIOEXAME: "Bloqueio de exame duplicado ativo",
        MESSAGEMBLOQUEIO: "Para inserir este exame mais de uma vez, solicite a remoção do bloqueio de exame duplicado, no cadastro de exames",
        BLOQUEIOEXAMEGENERO: "Bloqueio de exame por gênero",
        BLOQUEIOEXAMEGENEROMENSAGEM: "Este exame não pode ser escolhido para este paciente.",
        INSERIREXAME: "Inclusão de exame",
        MENSSAGEMINSERIREXAME: "Já foi encontrado o exame",
        NESTAGUIA: "nesta guia",
        EXAMECOMPLEMENTARMENSAGEM: "O exame complementar ",
        NAOPODEINSERIR: " não pôde ser inserido para este paciente.",
        BLOQUEAREXAMES: "Bloquear exames selecionados",
        DESBLOQUEAREXAMES: "Desbloquear exames selecionados"
    },
    PACIENTE: {
        NOME: "Nome",
        NOMESOCIAL: "Nome Social",
        FONE: "Fone",
        CELULAR: "Celular",
        SEXO: "Sexo",
        SELECIONARSEXO: "Selecione o sexo",
        FEM: "Feminino",
        MASC: "Masculino",
        NASCIMENTO: "Nascimento",
        IDADE: "Idade",
        CALCULARIDADE: "Calcular idade em",
        ANOS: "Anos",
        MESES: "Meses",
        DIAS: "Dias",
        PESO: "Peso (kg)",
        ALTURA: "Altura (cm)",
        EMAIL: "Email",
        RG: "RG",
        CPF: "CPF",
        CARTAONACIONAL: "Cartão nacional de saúde",
        INSCRICAO: "Inscrição",
        NOMEMAE: "Nome da mãe",
        RACA: "Raça",
        ESPECIE: "Espécie",
        RESPONSAVEL: "Responsável",
        OBSERVACOES: "Observações",
        USUARIO: "Usuario",
        CAMERA: "Câmera",
        CAMERAMENSAGEM: "Clique para capturar pela webcam",
        ARQUIVO: "Arquivo",
        USUARIOWEB: "Criar usuário web",
        CANCELARUSUARIO: "Cancelar usuário web",
        INDICACAO: "Indicação",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MENSAGEMNASCIMENTO: "Valor informado para a data de nascimento inválido!",
        MENSAGEMIDADE: "Valor informado para a idade inválido!",
        EMAILERRO: "O e-mail ",
        USADO: " já está sendo usado pelo usuario do ",
        CODIGOUSADO: " código ",
        RACAOBRIGATORIO: "Raça obrigatória.",
        ESPECIEOBRIGATORIO: "Especie obrigatória.",
        ESPECIEINVALIDA: "Especie inválida",
        RESPONSAVELOBRIGATORIO: "Responsável obrigatório",
        CPFOBRIGATORIO: "CPF ou passaporte obrigatório",
        USARIOWEBNAOCRIADO: "Não é possível criar usuário web sem email.",
        CPFUSADO: "Este CPF já está em uso.",
        ERROSALVAR: "Erro ao salvar paciente, verificando cpf: ",
        ERROSALVARPACIENTE: "Erro ao salvar paciente: ",
    },
    BUSCACEP: {
        CEP: "CEP",
        CEPMESSAGEM: "CEP não encontrado",
        ENDERECO: "Endereço",
        BAIRRO: "Bairro",
    },
    BUSCACIDADE: {
        CIDADE: "Cidade",
        NENHUMACIDADE: "Nenhuma cidade selecionada",
    },
    EXAMESATRASADOS:{
        PEDIDO: "Pedido", 
        REFERENCIA: "Referência",
        EXAMES: "Exames",
        MOTIVO: "Motivo",
    },
    INTEGRACAO: {
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        DATA: "Data",
        MENSAGEM: "Mensagem",
    },
    WORKFLOW: {
        DATARETORNO: "Data retorno",
        ETIQUETA: "Etiqueta",
        IMPRIMIRETIQUETA: "Imprimir etiqueta",
        COMPROVANTE: "Comprovante",
        IMPRIMIRCOMPROVANTE: "Imprimir comprovante",
        VINCULARETIQUETA: "Vincular etiquetas pré-coleta",
        SALVAR: "Salvar",
        MENSAGEMVINCULO: "Vínculo(s) realizado(s) com sucesso.",
        ERROIMPRIMIR: "Erro na impressão de etiquetas: ",
    },
    COMPROVANTEVARIAVEL:{
        VERPDF: "Ver PDF",
        IMPRIMIR: "Imprimir",
        CANCELAR: "Cancelar",
        MENSAGEMIMPRESSAO: "Erro na impressão de comprovante: "
    },
    MODALUSUARIOCONVENIO: {
        CONFIGUSUARIO: "Configuração do Usuário",
        VIDEOS: "Vídeos de treinamento",
        AGENTE: "Agente de impressão",
        SAIRSISTEMA: "Sair do sistema",        
        ALTERARSENHA: "Alterar senha",
        FECHAR: "Fechar",
    },
    MODALUSUARIO: {
        TITULO: "Configuração do Usuário",
        GUICHE: "Guichê",
        GUICHEPLACEHOLDER: "Identificação do guichê",
        ETIQUETAPADRAO: "Etiqueta padrão",
        ETIQUETAPADRAOPLACEHOLDER: "Modelo de etiqueta",
        ALTERARSENHA: "Alterar senha",
        LINKSUTEIS: "Links úteis:",
        SUPORTEREMOTO: "Suporte remoto",
        VIDEOSTREINAMENTO: "Vídeos de Treinamento",
        AGENTEIMPRESSAO: "Agente de Impressão",
        FECHAR: "Fechar",
        SALVAR: "Salvar"
    },
    MODALIMPRESSAO: {
        IMPRESSAO: "Impressão",
        CONEXAO: "Conexão",
        IMPRIMIR: "Imprimir",
        FECHAR: "Fechar",
        SALVAR: "Salvar",
    },
    MODALCANCELANDOEXAME: {
        CANCELAREXAME: "Cancelar exame",
        ATENCAO: "Atenção!",
        AVISOCANCELAMENTO: "Este procedimento irá cancelar os exames selecionados",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",
        CANCELAR: "Cancelar",
    },
    MODALCANCELANDOAGENDA: {
        CANCELARAGENDA: "Cancelar agendamento",
        ATENCAO: "Atenção!",
        AVISOCANCELAMENTO: "Este procedimento irá cancelar o agendamento",        
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",
        CANCELAR: "Cancelar",
    }, 
    MODALALTERACAOAGENDA: {
        ALTERARAGENDA: "Alterar agendamento",
        ATENCAO: "Atenção!",
        AVISOALTERACAO: "Este procedimento irá alterar a data do agendamento",        
        MOTIVOS: "Motivos",
        CONFIRMARALTERACAO: "Confirmar alteração",
        CANCELAR: "Cancelar",
    },          
    MODALRESPOSTAPESQUISA: {
        NOMEARQUIVO: "Nome do arquivo",
        CLIQUEDOWNLOAD: "Clique para fazer o download do arquivo",
        BAIXARARQUIVO: "Baixar arquivo",
        ALTERARARQUIVO: "Alterar arquivo",
        SALVAR: "Salvar pesquisas",
    },
    GUIAEXAME: {
        RESULTADOVINCULADO: "'Resultado de exame vinculado ao item ",
        GUIAVINCULADO: " da guia ",
        EXCLUIREXAME: "Excluir o exame",
        LIGADESLIGAURGENCIA: "Clique ligar/desligar a urgência",
        LIGADESLIGAMATERIAL: "Clique ligar/desligar o recebimento do material",
        AUTORIZACAOCONVENIO: "Este exame requer autorização do convênio!"
    },
    CADASTROGENERICO: {
        NENHUMEXAME: "Nenhum exame encontrado",
        TOTAL: "Total"
    },
    EQUIPAMENTOS: {
        RESULTADOACONFIRMAR: "Resultados a confirmar:",
        CONFIRMARRESULTADO: "Confirmar resultado",
        VINCULARAMOSTRA: "Vincular amostra",
        CONFIGEQUIPAMENTO: "Configurar equipamento",
        ENVIAWORKLIST: "Enviar worklist",
        MANUTENCAOEQUIP: "Manutenções equipamento",
    },
    CONFIRMARINTERFACE: {
        BUSCARGUIA: "Buscar Guia",
        SELECIONAR: "Selecionar todos",
        DESMARCAR: "Desmarcar todos",
        GUIA: "Guia",
        AMOSTRA: "AMOSTRA",
        VALORCRITICO: "Fora do valor crítico",
        RESULTADOANTERIOR: "Resultados anteriores",
        EDITARRESULTADO: "Editar resultado",
        CANCELAR: "Cancelar",
        SALVAR: "Salvar",
        GRAFICO: "Gráfico",
        RESULTADOCONFIRMADO: "O resultado foi confirmado com sucesso",
        MEDICAMENTOS: "Medicamentos: ",
        RESULTADOREPETIDO: "Resultado",
        REPETIDO: "repetido",
        INTERFACE: "Interface",
        CONFIRMACAO: "Confirmação",
        DELETANDO: "Não foi possível repetir o resultado. Verifique o console para mais informações.",
        PROCURARGUIA: "Procurar guia",
        GUIANAOENCONTRADA: "Guia não encontrada: ",
        EXCLUIRRESULTADO: "Excluir resultado",
        MENSSAGEMDELETANDO: "Tem certeza que deseja <b>excluir</b> este resultado? Esta ação não pode ser desfeita.<br/><b>",
        EXCLUIR: "Excluir",
        RESULTADO: "Resultado",
        EXCLUIDO: "Excluído",
        MENSAGEMERROEXCLUIR: "Não foi possível excluir o resultado. Verifique o console para mais informações.",
        MENSAGEMNAOALTERAR: "Não foi possível alterar o resultado: nenhum valor foi informado.",
        ALTERADO: "alterado para: ",
        MENSAGEMRESULTADOS: "Os resultados estão sendo confirmados, este processo pode levar alguns minutos.",
    },
    MODALBLOQUEIO: {
        BLOQUEAREXAME: "Bloquear exame",
        ATENCAO: "Atenção !",
        NOTIFICAOBLOQ: "Este procedimento irá bloquear os exames selecionados.",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARBLOQUEIO: "Confirmar bloqueio",
        CANCELAR: "Cancelar",
    },
    SAMPLELINK: {
        INTERFACE: "Interface",
        VINCULO: "Vínculo de amostra",
        TITULOVINCULAR: "Vincular amostra",
        CONFIRMAVINCULAR: "Confirma o vínculo dos resultados com a amostra ?",
        CODIGO: "Código de barras",
        CANCELAR: "Cancelar",
        EXCLUIR: "Excluir resultado",
        CONFIRMAEXCLUIR: "Tem certeza que deseja <b>excluir</b> este resultado? Esta ação não pode ser desfeita.",
        AMOSTRAREMOVIDA: "Amostra removida",
        AMOSTRAVINCULADA: "Amostra vinculada",
    },
    CONFIG: {
        BITSEGUNDO: "Bits/segundo",
        ATENCAO: "Atenção",
        NOTIFICACAO: "As alterações só surtirão efeito na próxima conexão da interface com o equipamento.",
        NOME: "Nome",
        INSTALACAO: "Instalação",
        VERSAO: "Versão",
        TIPOCONEXAO: "Tipo de conexão",
        PORTA: "Porta",
        BITSSEGUNDO: "Bits/segundo",
        PARIDADE: "Paridade",
        BITSDADOS: "Bits de dados",
        BITSPARADA: "Bits de parada",
        CONTROLEFLUXO: "Controle de fluxo",
        DIRETORIOIMPORTACAO: "Diretório de importação",
        DIRETORIOEXPORTACAO: "Diretório de exportação",
        ENVIARESULTADOUNIDADE: "Enviar resultado com unidade recebida",
        ENVIARTODOS: "Enviar automaticamente <b>TODOS</b> os resultados(sem confirmação)",
        NAOSOBREPOR: "Não sobrepor resultados já assinados",
        SEMAMOSTRAVINCULADA: "Equipamento envia resultados sem amostra vinculada",
        ENVIARESULTADONORMAL: "Enviar automaticamente resultados com valor normal:",
        VALORNORMAL: "Valor normal",
        APENASMATERIALENTREGUE: "Enviar apenas exames com material entregue",
        REENVIAR: "Re-enviar somente exames pendentes de digitação",
        REENVIARTODOS: "Re-enviar todos os exames da amostra",
        OCULTARCOMENTARIOS: "Não mostrar comentários recebidos na confirmação",
        IGNORAREMBRANCO: "Ignorar resultados em branco",
        IGNORARREPETIR: "Ignorar e repetir (voltar para pendência) resultados com valor:",
        VALORREPETIR: "Valor de repetição",
        TOTALIZADOR: "Totalizador:",
        TITULO: "Título",
        FORMULA: "Fórmula",
        EXAMES: "Exames",
        CODIGO: "Código",
        CODIGOEXAME: "Código do exame",
        NOMEEXAME: "Nome do exame",
        NENHUMEXAME: "Nenhum exame encontrado",
        CODIGOCAMPO: "Código do campo",
        NOMECAMPO: "Nome do campo",
        NENHUMCAMPO: "Nenhum campo encontrado",
        APELIDOEXAME: "Apelido do exame",
        VERSAOMASCARA: "Versão da máscara",
        AMOSTRANUMERO: "Amostra número",
        ORDENACAO: "Ordenação",
        DECIMAIS: "Decimais",
        UNIDADE: "Unidade",
        FORMULACALCULO: "Fórmula para cálculo",
        SORECEBE: "Só recebe",
        ENVIAASSINADO: "Envia assinado",
        MATERIAIS: "Materiais",
        NOMEMATERIAL: "Nome do material",
        NENHUMMATERIAL: "Nenhum material encontrado",
        INCLUIRMATERIAL: "Incluir material",
        INCUIREXAME: "Incluir exame",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        INTERFACE: "Interface",
        CONFIGURACAO: "Configuração",
        NENHUMA: "Nenhuma",
        ESPACO: "Espaço",
        IMPAR: "Ímpar",
        PAR: "Par",
        MARCA: "Marca",
        ERROSALVAR: "Erro ao salvar configuração do equipamento: ",
    },
    HISTORICO: {
        TITULO: "Histórico de alterações",
        INFORMACOES: "Informações",
    },
    CHECKPOINT: {
        ERRO: "Erro",
        FECHARMESAGEM: "Fechar mensagem",
        SETOR: "Setor",
        INCLUISETOR: "Inclua um setor",
        INCLUICONVENIO: "Inclua um convênio",
        CONTADOR: "Contador",
        AMOSTRA: "Amostra",
        GUIA: "Guia",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        EXAMES: "Exames",
        INCIDENCIA: "Incidência",
        ACEITARAMOSTRA: "Aceitar amostra",
        ACEITARRESTRICAO: "Aceitar amostra (Com restrição)",
        NAOACEITARAMOSTRA: "Não aceitar (Rejeição)",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        CODIGOGUIA: "Código da Guia",
        PENDENCIA: "Pendências",
        LOTEAMOSTRA: "Lote de amostras",
        FUNCIONARIORESP: "Funcionário responsável:",
        IDENTIFICADOR: "Identificador",
        TEMPERATURA: "Temperatura (C°)",
        UMIDADE:"Umidade (%)",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        EMITEBANCADA: "Emitir Bancada",
        ENVIAINTEGRADOR: "Enviar para Integrador",
        INTEGRADOR: "Integrador",
        IMPRIMIR: "Imprimir",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Atendimento",
        LOTEAMOSTRAS: "Lote de Amostras",
        CHECKPOINT: "Checkpoint",
        BANDEJA: "Bandeja",
        AVISOBANDEJA: "Posições insuficientes na bandeja para inserção da amostra",
        AMOSTRASELECIONADA: "Amostra já selecionada",
        AMOSTRAJAINCLUIDA: "A amostra {msg} ja está incluída.",
        MENSAGEMFILTROSETOR: "Amostra não respeita o filtro de Setor.",
        MENSAGEMFILTROCONVENIO: "Amostra não respeita o filtro de Convênio.",
        AMOSTRANAOSELECIONADA: "Nenhuma amostra selecionada.",
        AMOSTRASENVIADAS: "Amostras enviadas. O PDF da bancada pode levar alguns minutos para ser gerado, será apresentado em nova aba.",
        CODIGOGUIANAOENCONTRADO: "Guia não encontrada. Verifique o conteúdo pesquisado e tente novamente.",
        LOTENAOENCONTRADO: "Lote não encontrado. Verifique o conteúdo pesquisado e tente novamente.",
        AMOSTRANAOENCONTRADA: "Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.",
        EFETUADOCHECKPOINT: "Checkpoint já efetuado para está amostra",
        DESCRICAO: "Descrição",
        SOLICITARNOVAAMOSTRA: "Nova Amostra",
        ALIQUOTAAMOSTRA: "Alíquota/Lâmina",
        AMOSTRASTATUS: "Status",
    },
    MANUTENCAOLISTA: {
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        NOMETECNICO: "Nome Tecnico",
        DATAAGENDADA: "Data agendada",
        FUNCIONARIO: "Funcionário",
        EDITAR: "Editar",
        INTERFACE: "Interface",
        MANUTENCOES: "Manutenções",
    },
    MANUTENCAO: {
        MENSAGEMSALVAR: "A manutenção está sendo salva, este processo pode levar alguns segundos.",
        NOMETECNICO: "Nome Tecnico",
        DATAAGENDADA: "Data agendada",
        FUNCIONARIO: "Funcionário",
        DESCRICAO: "Descrição",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        INTERFACE: "Interface",
        MANUTENCOES: "Manutenções",
    },
    ATENDIMENTO: { 
        CONSULTARGUIA: "Consultar Guia",
        CODIGO: "Código",
        REFERENCIA: "Referência",
        AMOSTRA: "Amostra",
        PROCURARGUIA: "Procurar pelo código da guia",
        PROCURARREERENCIA: "Procurar pela referência da guia",
        PROCURARAMOSTRA: "Procurar pelo código da amostra",
        LISTARGUIAS: "Listar todas as guias",
        INCLUIRGUIA: "Incluir guia",
        NOMEPACIENTE: "Digite o nome do paciente",
        PROCURARPACIENTE: "Procurar paciente por nome/CPF/inscrição/data de nascimento",
        INSCRICAO: "Inscrição: ",
        RESPONSAVEL: "Responsável: ",
        AGENDA: "Agenda",
        PACIENTESAGENDADOS: "Pacientes agendados para hoje",
        NENHUMPACIENTEAGENDADO: "Nenhum paciente agendado para hoje",
        COLETADOMICILIAR: "Clique para ver a rota para coleta domiciliar desses pacientes",
        ABRIRCALENDARIO: "Calendário",
        INSPECAO: "Inspeção",
        COLETAMATERIAIS: "Coleta de materiais",
        LISTARTODOSAGENDAMENTOS: "Listar todos os agendamentos",
        AGENDAMENTOSHOJE: "Listar agendamentos para hoje",
        RECEBIMENTOAMOSTRA: "Recebimento de amostras",
        PENDENCIAS: "Listar pendências",
        QUANTIDADEPACIENTES: "Quantidade de pacientes aguardando coleta por local: ",
        NENHUMPACIENTES: "Nenhum paciente aguardando coleta",
        ULTIMASGUIAS: "Últimas guias alteradas",
        MOSTRARULTIMAS: "Mostrar últimas 5 guias",
        PROCURAEXAMES: "Procura de exames",
        ORCAMENTO: "Orçamentos",
        PROCURAR: "Procurar",
        LISTARTODOS: "Listar todos",
        INCLUIR: "Incluir",
        LOTEAMOSTRA: "Lote de amostras",
        LISTAR: "Listar",
        ENTREGARESULTADOS: "Entrega de Resultados",
        REGISTRAENTREGA: "Registrar entrega",
        BUSCA: "Busca",
        MENSAGEMCARTERINHA: "Carteirinha solicitada.",
        ERROCARTERINHA: "Erro ao enviar carteirinha: ",
        GUIA: "Guia",
        NAOENCONTRADA: " Não encontrada",
        GUIAREFERENCIA: "Guia com referência",
        GUIAAMOSTRA: "Amostra",
        SENHAS: "Senhas de atendimento",
        SALAESPERA: "Sala de Espera",
        SALAESPERALISTAR: "Listar Todos",
        SALAESPERAAGUARDANDO: "Quantidade de pacientes aguardando na sala de espera",
        ESPECIE: "Espécie",
        RACA: "Raça",
        AGENDAMENTO: "Agendamento",
    },
    GUIALIST: {
        BUSCARGUIAS: "Nome/Id/RG/CPF/Inscrição/DataDeNascimento",
        REFERENCIA: "Referência",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        LOCALORIGEM: "Local de Origem",
        EMISSAO: "Emissão",
        ATENDIMENTO: "Atendimento",
        GUIA: "Guias",
    },
    AGENDA: {
        RESPOSAVELFILTRO: "Filtrar por tipo de agendamento",
        ATENDIMENTO: "Atendimento",
        CONFIRMARREAGENDAR: "Deseja re-agendar o procedimento, o agendamento atual será cancelado",
        SELECIONEEXECUTANTE: "Selecione o executante",
        ATENCAO: "Atenção",
        AGENDA: "Agenda",
        MENSAL: "Mensal",
        DIARIO: "Diário",
        INCLUIRPACIENTE: "Incluir Paciente",
        BLOQUEARHORARIO: "Bloquear Horário",
        DESBLOQUEARHORARIO: "Desbloquear Horário",        
        HORARIOLIVRE: "Horário Livre",        
        HORARIOBLOQUEADO: "Horário Bloqueado",   
        CONFIRMACANCELAMENTO: "Confirma o cancelamento desse agendamento?",  
        CONFIRMAPACIENTE: "Confirmar o agendamento?", 
        ERROAOSALVAR: "Erro ao salvar agendamento, por favor, verifique o horário e tente novamente",  
        VERGUIAPACIENTE: "Ver guias do paciente",
        CRIARGUIA: "Criar guia",           
        ADICIONAREVENTO: "Adicionar Evento",
        ESCALASNAOCADASTRADAS: "Não existem horários livres para este dia!",
        PACIENTENAOCONFIRMADO: "Paciente Não Confirmado!",
        PACIENTECONFIRMADO: "Paciente Confirmado!",
        PACIENTEENCAIXE: "Encaixe!",
        ERROSALVAR: "Erro ao salvar",
        NAOAGENDADO: "Não Agendado",
        HORARIORESERVADO: "Horário Reservado",   
    },
    AGENDAMODAL: {
        ERRO: "Erro",
        MENSAGEMFECHAR: "Fechar mensagem",
        DATAHORA: "Data e hora",
        SELECIONARHORA: "Selecione o horário",
        NOVOPACIENTE: "Incluir um novo paciente",
        TIPOAGENDAMENTO: "Tipo de Agendamento",
        NOVOSTATUS: "Incluir um novo status",
        FUNCIONARIORESPONSAVEL: "Executante",
        NENHUM: "Nenhum",
        OBSERVACAO: "Observação",
        CONFIRMAR: "Confirmar",
        CANCELAR: "Cancelar",
        MENSAGEMPACIENTE: "O Paciente precisa ser informado",
        MENSAGEMTIPOAGENDAMENTO: "O Tipo de agendamento precisa ser informado",
        MENSAGEMDATAHORA: "A data/hora não pode ser inferior a data/hora atual",
        RESPONSAVELOCUPADO: "Responsável ocupado",
        RESPONSAVELOCUPADOMENSAGEM: "Responsável já possuí agendamento neste horário",
        HORARIOPREENCHIDO: "Horário preenchido",
        MENSAGEMHORARIOPREENCHIDO: "Confirma a inclusão do agendamento no mesmo horario de outro paciente?",
        ESPECIE: "Espécie",
        RACA: "Raça",
        RESPONSAVEL: "Responsável",
    },
    AGENDARELACAO: {
        SELECIONEPERIODO: "Selecione o Período",
        FILTRAR: "Filtrar",
        FILTRARRESPONSAVEL: "Filtrar por funcionário",
        FILTRARAGENDAMENTO: "Filtrar por tipo de agendamento",
        FILTRARSTATUS: "Filtrar por status",
        DATA: "Data",
        ENDERECO: "Endereço",
        BAIRRO: "Bairro",
        CEP: "CEP",
        CIDADE: "Cidade",
        FONE: "Fone",
        OBSERVACAO: "Observação",
        TIPOAGENDAMENTO: "Tipo de Agendamento",
        FUNCIONARIORESPONSAVEL: "Funcionário Responsável",
        ATENDIMENTO: "Atendimento",
        RELACAOAGENDAMENTO: "Relação de Agendamento",
        HISTORICO: "Ver Histórico",
        BUSCAPACIENTE: "Buscar Paciente",
        PROCURAR: "Procurar por nome",
        ABRIRGUIA: "Abrir Guia"
    },
    SALAESPERA: {
        SELECIONEPERIODO: "Selecione o Período",
        FILTRAR: "Filtrar",
        NUMEROGUIA: "Número da guia",
        FILTRARRESPONSAVEL: "Filtrar por funcionário",
        FILTRARAGENDAMENTO: "Filtrar por tipo de agendamento",
        FILTRARSTATUS: "Filtrar por status",
        DATA: "Data",
        STATUS: "Status",
        IDADE: "Idade",
        URGENTE: "Exame Urgente",
        OBSERVACAO: "Observação",
        TIPOAGENDAMENTO: "Tipo de Agendamento",
        FUNCIONARIORESPONSAVEL: "Funcionário Responsável",
        ATENDIMENTO: "Atendimento",
        SALAESPERA: "Sala de Espera",
        ORDEM: "Ordem",
        HISTORICO: "Ver Histórico",
        CHECKOUT: "Checkout",
        FAZERCHECKOUT: "Deseja fazer Checkout?",
        REALIZARATENDIMENTO: "Deseja realizar Atendimento?",        
        ACAO: "Ações",
    },    
    COLETA: {
        LOCALORIGEM: "Local de origem",
        SETOR: "Setor",
        PERIODO: "Período",
        RESULTADOSPORPAGIA: "Resultados por página",
        ORDENARPACIENTE: "Ordenar por nome do paciente",
        FILTRAURGENTES: "Filtrar apenas exames urgentes",
        FILTRAR: "Filtrar",
        GUIA: "Guia",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        EXAMEURGENTE: "Exame urgente",
        FUNCIONARIORESPONSAVEL: "Funcionário Responsável",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Atendimento",
        COLETA: "Coleta",
        MENSAGEMCOLETA: "Ao menos uma guia precisa ser selecionada para envio",
    },
    RECEBIMENTOAMOSTRAS: {
        AMOSTRA: "Amostra",
        MOTIVO: "Selecione o Motivo",
        GUIA: "Guia",
        DATAEMISSAO: "Data de Emissão",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        SETOR: "Setor",
        EXAMES: "Exames",
        ACONDICIONAMENTOCONGELADA: "Acondicionamento: Congelada",
        ACONDICIONAMENTOREFRIGERADA: "Acondicionamento: Refrigerada",
        ACONDICIONAMENTOAMBIENTE: "Acondicionamento: Ambiente",
        ACEITARAMOSTRA: "Aceitar amostra",
        ACEITARAMOSTRARESTRICAO: "Aceitar amostra (Com restrição)",
        REJEITARAMOSTRA: "Não aceitar (Rejeição)",
        FILTROSEMRESULTADO: "Não existem amostras que atendem ao filtro selecionado !",
        SOMENTEMATERIALENTREGUE: "Somente serão exibidos os exames que não tiveram o material entregue. Verifique o conteúdo pesquisado e tente novamente.",
        AMOSTRARECEBIDA: "As amostras estão sendo recebidas.",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        REFERENCIA: "Referência",
        SELECIONACONVENIO: "Selecione o convênio",
        LOTEAMOSTRAS: "Lote de Amostras",
        FUNCIONARIORESPONSAVEL: "Funcionário responsável: ",
        IDENTIFICADOR: "Identificador",
        TEMPERATURA: "Temperatura (C°)",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        IMPRIMIR: "Imprimir",
        LIMPAR: "Limpar",
        CANCELAR:  "Cancelar",
        ATENDIMENTO: "Atendimento",
        RECEBIMENTO: "Recebimento de Amostras",
        AMOSTRASELECIONADA: "Amostra já selecionada",
        MENSAGEMAMOSTRA: "A amostra ",
        AMOSTRAINCLUIDA: "já incluída na lista.",
        AMOSTRANAOPERTENCE: "Essa amostra não pertence ao paciente referenciado",
        ERROINFO: "Erro ao buscar informações",
        MOTIVOOBRIGATORIO: "Motivo de recebimento obrigatório.",
        IDENTIFICACAO: "Identificação do Funcionário obrigatória!",
        IDENTIFICACAOPACIENTE: "Identificação do Paciente obrigatória!",
    },
    ORCAMENTOLISTA: {
        PROCURARNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        ID: "Id",
        FUNCIONARIO: "Funcionário",
        EMISSAO: "Emissão",
        VALIDADE: "Validade",
        VALOR: "Valor (R$)",
        EXPIRADO: "Expirado",
        ATENDIMENTO: "Atendimento",
        ORCAMENTOS: "Orçamentos",
        EDITAR: "Editar",
    },
    ORCAMENTOCADASTRO: {
        DESCONTO: "Desconto",
        INCLUIRPACIENTE: "Incluir um novo paciente",
        INCLUIRMEDICO: "Incluir um novo solicitante",
        DATAEMISSAO:  "Data de Emissao",
        DATAVALIDADE: "Data de Validade",
        EXAME: "Exames",
        INCLUIREXAME: "Incluir exame",
        NENHUMEXAME: "Nenhum exame encontrado",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        CODIGO: "Código",
        APELIDO: "Apelido",
        NOME: "Nome",
        GRUPOEXAME: "Grupo de Exame",
        PRECO: "Preço",
        TOTALEXAMES: "Total de Exames",
        OBSERVACOES: "Observações",
        IMPRIME: "Imprime",
        ENVIAREMAIL: "Enviar por E-mail",
        ORCAMENTOEXPIRADO:  "Orçamento Expirado",
        CRIARGUIA: "Criar Guia",
        SALVAR: "Salvar",
        CANCELAR:  "Cancelar",
        ATENDIMENTO: "Atendimento",
        ORCAMENTO: "Orçamento",
        PACIENTEOBRIGATORIO: "Paciente obrigatório",
        INFORMARPACIENTE: "Especifique um paciente para continuar.",
        ERROSALVAR: "Erro ao salvar orcamento: ",
        TITULOENVIAREMAIL: "Enviar orçamento por email",
        EMAIL: "Email",
        EXAMETITULO: "Exame já incluído",
        MENSAGEMCONFIRMACAO: "Confirma a inclusão do exame: <b>",
    },
    PROCURAEXAME: {
        UNIDADE: "Unidade",
        NUMEROGUIA: "Número da guia",
        LOCAL: "Local",
        RESPONSAVEL: "Responsável",
        SETOR: "Setor",
        EXAME: "Exame",
        EXAMESTATUS: "Status dos exames",
        SELECIONESTATUS: "Selecione o status do exame",
        EXAMEATRASADO: "Exames atrasados",
        EMISSAO: "Emissão",
        SOLICITACAO: "Solicitação",
        COLETA: "Coleta",
        RETORNO: "Retorno",
        ENTREGA: "Entrega",
        RESULTADOPAGINA: "Resultados por página",
        EXAMES: "Exames",
        ALTERARPRAZO: "Alterar prazo",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        PACIENTENOME: "Nome do Paciente",
        APELIDO: "Apelido",
        DATAENTREGA: "Data de Entrega",
        OBSERVACOES: "Observações",
        STATUS: "Status",
        ATENDIMENTO: "Atendimento",
        PROCURAEXAME: "Procura de Exames",
    },
    ENTREGARESULTADOS: {
        CODIGOGUIA: "Código da Guia",
        LOADING: "Loading",
        INCLUIR: "Incluir",
        GUIA: "Guia",
        DATAEMISSAO: "Data de Emissão",
        EXAMES: "Exames",
        ENTREGAR: "Entregar",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Atendimento",
        ENTREGARESULTADOS: "Entrega de Resultados",
        MENSAGEMDESTINO: "Destino da Entrega",
    },
    LISTALOTEAMOSTRA: {
        PROCURADESCRICAO: "Procurar por descrição",
        SELECIONAPERIODO: "Selecione o período",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        ID: "Id",
        DESCRICAO: "Descrição",
        ENVIO: "Envio",
        RECEBIMENTO: "Recebimento",
        EDITAR: "Editar",
        ATENDIMENTO: "Atendimento",
        LOTEAMOSTRAS: "Lote de Amostras",
        LISTAR: "Listar",
    },
    LOTEAMOSTRA: {
        NOME: "Nome",
        TEMPERATURA: "Temperatura: ",
        UMIDADE: "Umidade: ",
        AMOSTRAS: "Amostras",
        SELECIONADAS: "selecionadas",
        SELECIONARTODAS: "Selecionar Todas",
        DESSELECIONARTODAS: "Desselecionar Todas",
        IMPRIMEETIQUETA: "Imprime Etiquetas",
        ATENDIMENTO: "Atendimento",
        LOTE: "Lote de Amostras",
        IMPRIMIRLOTE: "Imprimir Lote"
    },
    INCLUIRLOTEAMOSTRAS: {
        FECHARMENSAGEM: "Fechar mensagem",
        AMOSTRA: "Amostra",
        AMOSTRANAOENCONTRADA: "Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.",
        AMOSTRANAOSELECIONADA: "É necessário a inserção de ao menos uma amostra!",
        CHECKBOXAMOSTRANAOSELECIONADO: "É necessário selecionar as amostras que deseja enviar!",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        SETOR: "Setor",
        EXAMES: "Exames",
        ACONDICIONAMENTOCONGELADA: "Acondicionamento: Congelada",
        ACONDICIONAMENTOREFRIGERADA: "Acondicionamento: Refrigerada",
        ACONDICIONAMENTOAMBIENTE: "Acondicionamento: Ambiente",
        DESCRICAO: "Descrição",
        CODIGOBARRAS: "Código de Barras",
        INCLUIR: "Incluir",
        TEMPERATURA: "Temperatura (C°)",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        CANCELAR: "Cancelar",
        ATENDIMENTO: "Atendimento",
        LOTEAMOSTRAS: "Lote de Amostras",
        SEMDESCRICAO: "Descrição obrigatória. Digite uma descrição para a amostra!",
    },
    GUIAATENDIMENTO: {
        DESVINCULAREXAME: "Desvincular Exame",
        CONFIRMREPETIR: "Confirmar repetição dos exames ",
        CONFIRMDESVINCULAR: "Confirmar remoção do vínculo dos exames",
        IDENTIFICACAO: "Identificação",
        REFERENCIA: "Referência",
        PAGAMENTO: "Pagamento",
        REALIZADO: "Realizado",
        AUTORIZADO: "Autorizado",
        PENDENTE: "Pendente",
        INCIDENCIA: "Guia com incidência",
        LOCALORIGEM: "Local de origem",
        SELECIONELOCALORIGEM: "Selecione o local de origem",
        EMITIDA: "Emitida em",
        INFOPACIENTE: "Abrir as informações do paciente",
        GUIASPACIENTE: "Listar todas as guias do paciente",
        LEITO: "Leito",
        INSCRICAO: "Inscrição: ",
        SEXO: "Sexo: ",
        NASCIMENTO: "Nascimento: ",
        HORASJEJUM: "Horas de jejum",
        CALCULARHORASJEJUM: "Clique para calcular as horas de jejum",
        ALTA: "Alta",
        ABRIRINFORMACOES: "Abrir as informações do solicitante",
        ABRIRINFORMACOESCONVENIO: "Abrir as informações do convênio",
        INCLUIRMEDICO: "Incluir um novo solicitante",
        RETORNO: "Retorno",
        UNIDADE: "Unidade: ",
        ALTERARUNIDADE: "Alterar unidade",
        TIPOAGENDAMENTO: "Tipo de Agendamento: ",
        EXAMES: "Exames",
        AMOSTRAS: "Amostras",
        INCLUIREXAME: "Incluir exame",
        PROCURARPOR: "Procurar por",
        APELIDO: "Apelido",
        NOME: "Nome",
        CODIGOEXTERNO: "Código externo",
        NENHUMEXAMEDISPONIVEL: "Nenhum exame disponível. Certifique-se que tenha escolhido o convênio.",
        BLOQUEADO: "BLOQUEADO: ",
        ITEMBLOQUEADO: "Bloqueado",
        DATASOLICITACAO: "Alterar data de solicitação dos exames selecionados",
        EXAMEREPETIDO: "Exame Repetido",
        CANCELAREXAMES: "Cancelar exames selecionados",
        BLOQUEAREXAMES: "Bloquear exames selecionados",
        DESBLOQUEAREXAMES: "Desbloquear exames selecionados",
        REPORTAREXAMES: "Reportar Exames",
        RECEBERMATERIAL: "Receber material do exame",
        ENTREGARESULTADOS: "Entrega de Resultados",
        UPLOADIMAGEM: "Anexar estudos",
        INFORMACOES: "Informações",
        CANCELARAMOSTRAS: "Cancelar amostras selecionadas",
        CANCELARCOLETA: "Cancelar coleta da amostra selecionada",
        RECEBERAMOSTRA: "Receber material da amostra",
        ARMAZENARSOROTECA: "Armazenar na Soroteca",
        IMPRIMIRETIQUETA: "Imprimir etiqueta",
        ALTERARCODIGO: "Alterar código de barras da amostra selecionada",
        REGISTRARINCIDENCIA: "Registrar incidência de amostra",
        ENVIARAMOSTRA: "Enviar amostra para integração",
        REIMPRIMIRINTEGRACAO: "Reimprimir etiqueta integração",
        SOLICITARNOVAAMOSTRA: "Solicitar nova amostra",
        INCLUIR: "Incluir",
        DIGITAR: "Digitar",
        COMPROVANTE: "Comprovante",
        GUIASP: "Guia SP/SADT",
        OUTRASDESPESAS: "Outras Despesas SADT",
        NOTIFICAR: "Notificar",
        ASSINAR: "Assinar",
        ETIQUETAS: "Etiquetas",
        RESULTADOS: "Resultados",
        VISUALIZAR: "Visualizar",
        IMPRIMIR: "Imprimir",
        EMAILMEDICO: "E-mail para solicitante",
        EMAILPACIENTE: "E-mail para paciente",
        OBSERVACOES: "Observações",
        ENTREGA: "Entrega",
        HIPOTESE: "Hipótese Diagnóstica",
        INDICACAO: "Indicação clínica",
        MEDICAMENTOS: "Medicamentos",
        AUTORIZACAO: "Autorização",
        GUIAOPERADORA: "Guia operadora",
        SENHA: "Senha",
        AUTORIZADOEM: "Autorizado em",
        VALIDADE: "Validade",
        CONSULTAR: "Consultar",
        CANCELAR: "Cancelar",
        FECHARMENSAGEM: "Fechar mensagem",
        DESCONTOACORDADO: "Desconto acordado",
        TOTAL: "Total",
        COMDESCONTO: "Com Desconto",
        CONDICAO: "Condição de pagamento",
        EXAME: "Exame",
        EXAMEFATURADO: "Exame faturado",
        PRECO: "Preço",
        GUIASADT: "Guias SADT's",
        NUMERO: "Número",
        INCLUIRGUIASADT: "Incluir guia SP/SADT",
        RESPOSTAS: "Respostas",
        NAOEXISTERESPOSTA: "Não existe nenhuma resposta",
        NENHUMAINFORMACAO: "Nenhuma informação encontrada",
        EDITAR: "Editar",
        VINCULOS: "Vínculos",
        GUIA: "Guia",
        VINCULARGUIA: "Guia a vincular",
        VINCULAR: "Vincular",
        ANEXOS: "Anexos",
        ARRASTEARQUIVOS: "Arraste os arquivos aqui ou clique para selecioná-los",
        APENASPDF: "Apenas formato PDF",
        ANEXO: "Anexo",
        REMOVERANEXO: "Remover anexo",
        SALVAR: "Salvar",
        COMPROVANTES: "Comprovantes",
        FICHA: "Ficha",
        ATENDIMENTO: "Atendimento",
        NOVA: "Nova",
        TITULOLOCALORIGEM: "Local de origem obrigatório",
        MENSAGEMLOCALORIGEM: "Especifique um local de origem para continuar.",
        MEDICOOBRIGATORIO: "Solicitante obrigatório",
        MENSAGEMMEDICOOBRIGATORIO: "Especifique um solicitante para continuar.",
        CONDICAOPAGAMENTO: "Condição de pagamento obrigatória.",
        MENSAGEMCONDICAOPAGAMENTO: "Especifique uma condição de pagamento para continuar.",
        TITULOEXAMESOBRIGATORIOS: "Exames obrigatórios",
        MENSAGEMEXAMESOBRIGATORIOS: "A guia precisa ter pelo menos 1 exame.",
        MEDICAMENTOSOBRIGATORIOS: "Medicamento obrigatório",
        MENSAGEMMEDICAMENTOSOBRIGATORIOS: "Estes exames precisam que informe os <b>medicamentos</b> em uso.",
        DATAMENSTRUACAO: "Data da Última Menstruação obrigatória",
        MENSAGEMDATAMENSTRUACAO: "Estes exames precisam que informe a <b>Data da Última Menstruação</b>.",
        PESO: "Peso obrigatório",
        MENSAGEMPESO: "Confirma a inclusão do peso do paciente ?",
        ALTURA: "Altura obrigatória",
        MENSAGEMALTURA: "Confirma a inclusão da altura do paciente ?",
        PACIENTEDESATIVADO: "Não é possível criar uma guia para um paciente desativado.",
        EXAMEJAINCLUIDO: "Exame já incluído",
        CONFIRMAINCLUSAO: "Confirma a inclusão do exame: <b> ",
        BLOQUEIAEXAMEDUPLICADO: "Bloqueio de exame duplicado ativo",
        BLOQUEIAEXAMEPERFIL: "Bloqueio exame de perfil",
        MENSAGEMDUPLICADO: "Para inserir este exame mais de uma vez, remova o bloqueio de exame duplicado no cadastro de exames",
        MENSAGEMBLOQUEIOPERFIL: "Não é possível inserir mais de uma vez exames do tipo perfil",
        BLOQUEIOGENERO: "Bloqueio de exame por gênero",
        MENSAGEMBLOQUEIOGENERO: "Este exame não pode ser escolhido para este paciente.",
        INCLUSAOEXAMES: "Inclusão de exame",
        ENCONTRADO: "Já foi encontrado o exame <b>",
        NESTAGUIA: "</b> nesta guia.",
        EXAMECOMPLEMENTAR: "O exame complementar ",
        NAOPODE: " não pôde ser inserido para este paciente.",
        REPETIREXAME: "Repetição de exame",
        CONFIRMAREPETIR: "Confirma repetição dos exames:<br/>",
        AMOSTRAENVIADA: "Amostra enviada para integrações: ",
        REIMPRIMIRAMOSTRAS: "Pedido de reimpressão para as amostras: ",
        EXAMESINTEGRACAO: "Exames enviados para integração",
        REGISTRARRESULTADOS: "Registrar entrega de resultados",
        MENSAGEMREGISTRARRESULTADOS: "Exames:<br/>",
        IMPRESSAOSOLICITADA: "Impressão de etiqueta solicitada para amostra: ",
        IMPRESSAOSOLICITADAGUIA: "Impressão de etiquetas solicitada para guia: ",
        NOVOCODIGO: "Novo código de barras",
        EXCLUIRANEXO: "Exclusão de anexo",
        CONFIRMAEXCLUSAO: "Confirma a exclusão do anexo: <b>",
        NENHUMPENDENTE: "Nenhum exame pendente selecionado.",
        MENSAGEMEMAILMEDICO: "Enviar laudo por email para o solicitante",
        EMAIL: "Email",
        EMAILPACIENTEMENSAGEM: "Enviar laudo por email para o paciente",
        ENVIADOSUCESSO: "Mensagem enviada com sucesso!",
        ALTERACODIGO: "Alterar código amostra",
        NAOEXISTERESPOSTAS: "Não existem respostas",
        TITULOINSCRICAOOBRIGATORIA: "Paciente não possui inscrição.",
        MENSAGEMINSCRICAOOBRIGATORIA: "Convênio selecionado exige inscrição do paciente.",
        ESPECIE: "Espécie: ",
        NOTIFICAREXAME: "Exame não é realizado hoje",
        DIASDISPONIVEIS: "Dias em que o exame é realizado: ",
        DOMINGO: "Domingo",
        SEGUNDA: "Segunda",
        TERCA: "Terça",
        QUARTA: "Quarta",
        QUINTA: "Quinta",
        SEXTA: "Sexta",
        SABADO: "Sábado",
    },
    SOROTECA:{
        AMPLIAR: "Ampliar",
        DIMINUIR: "Diminuir",
        GELADEIRA: "Geladeira",
        CONTAINER: "Container",
        CONSERVANTE: "Conservante",
        EM: "Em",
        EXAMES: "Exames",
        NUMEROGUIA: "Número da Guia",
        CODIGOAMOSTRA: "Cód. da Amostra",
        AMOSTRA: "Amostra",
        MATERIAL: "Material",
        MULTI:"Geladeira/Container/Posiçao",
        SETOR: "Setor",
        PERIODOENVIO: "Período de Envio",
        FILTRAR: "Filtrar",
        SALVAR: "Salvar",
        REMOVER: "Remover",
        CANCELAR: "Cancelar",
        LIMPAR: "Limpar",
        ANALISE: "Análise",
        SOROTECAARMAZENAR: "Soroteca - Armazenar",
        SOROTECA: "Soroteca",
        ATENCAOTITULO: "Atenção",
        AMOSTRASSELECIONADAS: "Escolher uma posição para as amostras:",
        REMOVERAMOSTRASTITULO: "Deseja Remover todas as amostras?",
        SIM: "Sim",
        NAO: "Não",
        AMOSTRAJACADASTRADA: "Amostra já cadastrada",
        AMOSTRANAOENCONTRADA: "Amostra não encontrada",
        AMOSTRANAODISPONIVEL: "Amostra não disponível",
        AMOSTRANAOPRONTACONFIRMAR: "Existem exames não prontos nesta amostra. Deseja incluir mesmo assim?",
        CONTAINERGELADEIRANAOSELECIONADO: "O container e a geladeira precisam ser selecionados",
        AMOSTRAERROAOSALVAR: "Ocorreu um erro ao salvar",
        POSICOESTITULO: "Posições:",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",   
        NAOEXISTEMREGISTROS: "Não existem registros para serem exibidos.", 
        RESULTADOASSINADO: "Resultado Assinado",   
        RESULTADODIGITADO: "Resultado Digitado",  
        AMOSTRACANCELADA: "Cancelado",   
        SELECIONARAOMENOSUM:"Ao menos um item precisa ser selecionado",   
        LOTEAMOTRAS: "Lote de Amostras",
        CONFIRMAINCLUSAOLOTE: "Confirmar inclusão do lote na soroteca ? As amostras atuais armazenadas serão removidas"
    },
    GUIAINDICADORES: {
        SEPSE: "SEPSE",
        DORTORACICA: "Dor torácica",
        AVC: "AVC",
        TRAUTOMALOGIA: "Traumatologia",
        RECEBERALTA: "Prestes a Receber Alta Médica",
        GUIAPENDENTE: "Guia pendente",
        NECESSIDADES: "Necessidades Especiais",
        GUIACONFERIDA: "Guia Conferida",
    },
    MODALINFOEXAME: {
        INFO: "Informações",
        GUIAITEM: "Guia - Item",
        EXAME: "Exame",
        SETOR: "Setor",
        DATAENTREGA: "Data de entrega",
        UNIDADE: "Unidade",
        CODIGOINTEGRACAO: "Código de integração:",
        ULTIMAMODIFICACAO: "Última modificação:",
        BANCADA: "Bancada",
        CONTARECEBER: "Contas a receber",
        NAUNIDADE: "na unidade",
        RESULTADORETIFICACAO: "Resultados anteriores à retificação",
        RESULTADOREPETICAO: "Resultados anteriores à repetição",
        RESULTADONOVO: "Resultados anteriores à nova amostra",
        FECHAR: "Fechar",
    },
    INFOAMOSTRA: {
        AMOSTRA: "Amostra",
        CODIGOBARRAS: "Código de Barras",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        ACONDICIONAMENTO: "Acondicionamento",
        VOLUME: "Volume",
        LOTE: "Lote",
        CODIGO: "Código de integração:",
        CODIGOINTEGRACAO: "Código de barras integração:",
        CONTADOR: "Contador",
        ORIGEM: "Origem",
        GRUPOINTERFACE: "Grupo interface",
        MEIOCOLETA: "Meio coleta",
        AMOSTRAMAE: "Amostra mãe",
    },
    SOLICITARNOVAAMOSTRA: {
        RECOLETA: "Recoleta",
        NOVAAMOSTRA: "Nova Amostra",
        AMOSTRABASE: "Amostra base:",
        CANCELARAMOSTRABASE: "Cancelar amostra base",
        SELECIONEMOTIVO: "Selecione o Motivo",
        SELECIONEMATERIAL: "Selecione o Material",
        EXAMES: "Exames:",
        SELECIONAEXAMES: "Selecione os exames",
        OBSERVACOES: "Observação",
        FECHAR: "Fechar",
        MOTIVOOBRIGATORIO: "Motivo obrigatório",
        MENSAGEMMOTIVOOBRIGATORIO: "Especifique um motivo para continuar.",
        MATERIALOBRIGATORIO: "Material obrigatório",
        MENSAGEMMATERIALOBRIGATORIO: "Especifique um material para continuar.",        
        SUCESSO: "Amostra incluída com sucesso",
        EMAILENVIADO: "E-mail enviado",
    },
    ALTERARDATASOLICITACAO: {
        ALTERAR: "Alterar data de solicitação",
        DATAHORA: "Selecione a data e hora",
        CANCELAR: "Cancelar",
    },
    ALTERARDATAULTIMAREFEICAO: {
        ALTERAR: "Selecione a data e hora da ultima refeição",
        CANCELAR: "Cancelar",
    },
    CANCELANDOAMOSTRA: {
        CANCELARAMOSTRA: "Cancelar amostra",
        CANCELARSELECIONADAS: "Este procedimento irá cancelar as amostras selecionadas.",
        AMOSTRAS: "Amostras",
        MOTIVOS: "Motivos",
        ATENCAO: "Atenção !",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",
        CANCELAR: "Cancelar",
    },
    CANCELANDOCOLETA: {
        CANCELARCOLETA: "Cancelar coleta da amostra",
        CANCELARSELECIONADAS: "Este procedimento irá cancelar a coleta da amostra selecionada.",
        AMOSTRAS: "Amostras",
        MOTIVOS: "Motivos",
        ATENCAO: "Atenção !",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",
        CANCELAR: "Cancelar",
    },
    INCIDENCIAAMOSTRA: {
        REGISTRAR: "Registrar incidência de amostra",
        MOTIVOS: "Motivos",
        AMOSTRAS: "Amostras",
        ATENCAO: "Atenção !",
        CANCELAR: "Cancelar",
    },
    DESBLOQUEIOEXAME: {
        DESBLOQUEIO: "Desbloquear exame",
        ATENCAO: "Atenção !",
        DESBLOQUEIASELECIONADOS: "Este procedimento irá desbloquear os exames selecionados.",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARDESBLOQUEIO: "Confirmar desbloqueio",
        CANCELAR: "Cancelar",
    },
    ALERTAEXAME: {
        REPORTAR: "Reportar Exame",
        EXAMES: "Exames",
        EMAIL: "Email",
        INFO: "Informações",
        MOTIVOS: "Motivos",
        CANCELAR: "Cancelar",
    },
    MENUANALISE: {
        MAPATRABALHO: "Mapa de Trabalho",
        EMISSAO: "Emissão",
        PROGRESSOPOR: "Progresso por:",
        BANCADA: "Bancada",
        SETOR: "Setor",
        GUIASURGENTESINDICADORES: "Guias Urgentes / com Indicadores",
        LISTARPENDENCIAS: "Listar Pendências",
        GUIASURGENTESLOCAL: "Guias Urgentes por Local",
        GUIASINDICADORES: "Guias com Indicadores",
        ACOMPANHAMENTO: "Acompanhamento",
        EXAMES: "Exames",
        EXAME: "Exame",
        ENTREGAS: "Entregas:",
        AMOSTRAS: "Amostras:",
        ESTUDOS: "Estudos:",
        TODOS: "Todos",
        DIGITACAORESULTADOS: "Digitação de resultados",
        PROCURARPOR: "Procurar por ",
        CODIGOBARRAS: "Código de barras",
        GUIA: "Guia",
        PROCURARSETOR: "Procurar por setor",
        LISTARPENDENCIASDIGITACAO: "Listar pendências de digitação por:",
        LOCAL: "Local",
        NENHUMAPENDENCIAFILTROSELECIONADO: "Nenhuma pendência para o filtro selecionado",
        ASSINATURARESULTADOS: "Assinatura de resultados",
        EMLISTA: "em Lista",
        PORGUIA: "por Guia",
        SOMENTEURGENTES: "Somente urgentes",
        LISTARPENDENCIASASSINATURA: "Listar pendências de assinatura por:",
        DIGITACAORESULTADOSLOTE: "Digitação de Resultados em Lote",
        DIGITACAOLOTE: "Digitação em Lote",
        EMISSAORESULTADOSLOTE: "Emissão de Resultados por Lote",
        EMITIRLOTE: "Emitir por Lote",
        SOROTECA: "Soroteca",
        ARMAZENARAMOSTRAS: "Armazenar amostras",
        SOLICITACAO: "Solicitação",
        COLETA: "Coleta",
        ENTREGA: "Entrega",
        BANCADASETOR: "Bancada e Setor",
        AMOSTRA: "Amostra",
        EXAMESBLOQUEADOS: " exames bloqueados",
        EXAMEBLOQUEADO: " exame bloqueado",
        AMOSTRASINCIDENCIAS: " amostras com incidências",
        AMOSTRAINCIDENCIA: " amostra com incidência",
        ESTUDOSVINCULO: " estudos sem vinculo",
        ESTUDOVINCULO: " estudo sem vinculo",
        BUSCARLOTE: "Buscar Lote",
    },
    MENUFINANCEIRO: {
        FORNECEDOR: "Fornecedor",
        SOMENTEPARTICULARES: "Somente particulares",
        SOMARVALORESPARCELAS: "Somar valores das parcelas",
        FILTRARDATAPROCESSAMENTO: "Filtrar por data de processamento",
        RECEBIMENTOSATRASADOS: 'Recebimentos atrasados',
        PAGAMENTOSPROXIMOS: 'Pagamentos próximos',
        CONTASRECEBER: "Contas a receber",
        LOTECONTARECEBER: "Lote de contas a receber",
        CONTASPAGAR: "Contas a pagar",
        PERIODO: "Selecione o período",
        LOTECONTAPAGAR: "Lote de contas a pagar",
        CONTACORRENTESCARTAO: "Conta correntes / Cartões",
        CENTROCUSTO: "Centro de custo",
        BANDEIRAS: "Bandeiras",
        RELATORIOSRAPIDOS: "Relatórios rápidos",
        ESTATISTICAS: "Estatísticas",
        BAIXAPAGAMENTOSPERIODO: "Baixa de pagamentos por período",
        PLACEHOLDERPROCURAR: "Procurar",
        BTNLISTARTODOS: "Listar todos",
        BTNINCLUIR: "Incluir",
        BTNBAIXAR: "Baixar",
        BTNFATURAR: "Faturar",
        BTNFLUXOCAIXA: "Fluxo de caixa",
        BTNFLUXO: "Fluxo",
        BTNFECHAMENTOCAIXA: "Fechamento de caixa",
        BTNFECHAMENTO: "Fechamento",
        BTNRELACAODEBITOS: "Relação de débitos",
        BTNDEBITOS: "Débitos",
        BTNESTATISTICAS: "Estatísticas",
        BTNLISTARRECEBIMENTOSATRASADOS: "Listar recebimentos atrasados",
        BTNLISTARPARCELASFUTURAS: "Listar parcelas futuras",
        LBLBAIXAPAGAMENTOSPERIODO: "Baixa de pagamentos por período",
    },
    CONCILIACAOBANCARIA: {
        ID: "Id",
        CONCILIACAO: "Conciliação bancária",
        CONCILIAR: "Conciliar",
        DESCONCILIAR: "Desconciliar",
        VALORESPERADO: "Valor esperado",
        VALORREGISTRADO: "Valor registrado",
        VALOR: "Valor",
        DATAVENCIMENTO: "Data de Vencimento",
        DATATRANSACAO: "Data da transação",
        TRANSACAO: "Transação",
        TOTALSELECIONADO: "Total selecionado",
        VALORTRANSACAO: "Valor transação",
        BUSCAR: "Buscar",
        CONFIRMAR: "Confirmar",
        FILTRO: "Filtro",
        SELECIONADO: "Selecionado",
        ESPECIEPAGAMENTO: "Espécie de pagamento",
        TIPOREGISTRO: "Tipo de registro",
        NOTAFISCAL: "Nota Fiscal",
        REFERENCIA: "Referência",
        PARCELA: "Parcela",
        NENHUMLANCAMENTORELACIONADO: "Nenhum lançamento relacionado",
        DATASALDO: "Data saldo",
        SITUACAOSALDO: "Situação",
        SALDO: "Saldo",
        ESTACONCILIADO: "Conciliado",
        NAOESTACONCILIADO: "Não conciliado",
        EXTRATOCONCILIACAO: "Extrato conciliação",
        APARTIRDE: "A partir de",
        IMPORTAROFX: "Importar OFX",
        IMPORTACOES: "Importações",
        IMPORTADOPOR: "Importado por",
        CONTACORRENTE: "Conta Corrente",
        SALDOANTERIOR: "Saldo anterior",
        ARQUIVO: "Arquivo",
        PERIODOINICIAL: "Período inicial",
        PERIODOFINAL: "Período final",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        CANCELAR: "Cancelar",
    },
    CONTASRECEBERLISTAR:{
        PLACEHOLDERPROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        DATA: "Data",
        DEPARTAMENTO: "Departamento",
        CONTACORRENTE: "Conta Corrente",
        QTDEXAMES: "Qtde. Exames",
        VALOR: "Valor",
        PDFSINTETICO: "PDF Sintético",
        XLSSINTETICO: "XLS Sintético",
        PDF: "PDF",
        XLS: "XLS",
        TITULO: "Contas a Receber"
    },
    CONTASRECEBERCADASTRO:{
        DOCUMENTO: "Documento",
        PRESTADOR: "Prestador",
        TOMADOR: "Tomador",
        VALOR: "Valor",
        SITUACAO: "Situação",
        IDENTIFICACAONOTAFISCAL: "Identificação da Nota Fiscal",
        NUMERONFSE: "Número NFSE",
        SERIE: "Sério",
        LOTE: "Lote",
        NUMERO: "Número",
        CODIGOVERIFICACAO: "Código de Verificação",
        AUTORIZACAO: "Autorização",
        MENSAGEM: "Mensagem",
        CONTACORRENTE: "Conta Corrente",
        DATA: "Data",
        PLACEHOLDERDATA: "Selecione uma Data",
        NOTAFISCAL: "Nota Fiscal",
        FUNCIONARIO: "Funcionário",
        LOCALORIGEM: "Local de Origem",
        UNIDADE: "Unidade",
        OBSERVACOES: "Observações",
        FECHARFATURA: "Fechar Fatura?",
        FATURAFECHADA: "Fatura Fechada",
        TISS: "TISS",
        TISSLOTEGUIAS: "TISS Lote Guias",
        GERARDOCUMENTO: "Gerar Documento",
        PDF: "PDF",
        PDFSINTETICO: "PDF Sintético",
        XLS: "XLS",
        EMITIRNOTAFISCAL: "Emitir Nota Fiscal",
        CONSULTARNOTAFISCAL: "Consultar Nota Fiscal",
        PDFNOTAFISCAL: "PDF Nota Fiscal",
        CANCELARNOTAFISCAL: "Cancelar Nota Fiscal",
        CANCELARFATURA: "Cancelar Fatura",
        PARCELACANCELADA: "Parcela Cancelada",
        PARCELARVALOR: "Parcelar Valor",
        DESCONTO: "Desconto",
        DESCONTOTOTAL: "Desconto Total: R${0}",
        PARCELAS: "Parcelas",
        MENSAGEMVALORNAOBATE: "A soma total dos valores ({0}) não bate com o valor total dos exames ({1}).",
        VENCIMENTO: "Vencimento",
        PLACEHOLDERSELMOTIVO: "Selecione o Motivo",
        ESPECIE: "Espécie",
        DADOS: "Dados",
        AGENCIA: "Agência",
        PLACEHOLDERAUTORIZACAO: "Autorização",
        BANCO: "Banco",
        BANDEIRA: "Bandeira",
        RECEBIMENTO: "Recebimento",
        BOLETO: "Boleto",
        CONTROLEINTERNO: "Controle Interno",
        REEMITIRBOLETO: "Re-Emitir Boleto",
        MENSAGEMREEMITIRBOLETO: "Deseja fazer a Re-Emissão do boleto?",
        GERARBOLETO: "Emitir Boleto",
        MENSAGEMCANCELARBOLETO: "Deseja cancelar o boleto atual? ",
        CANCELARBOLETO: "Cancelar Boleto",
        VISUALIZARBOLETO: "Visualizar  Boleto",
        ANALISAR: "Analisar",
        SITURACAO: "Situação",
        RECEBIDOEM: "Recebido em",
        CANCELAR: "Cancelar",
        IMPRIMIRRECIBO: "Imprimir Recibo",
        CANCELADO: "Cancelado",
        PAGAMENTOCANCELADO: "Pagamento Cancelado",
        LIBERADO: "Liberado",
        ANALISE: "Análise",
        LIBERAR: "Liberar",
        REJEITAR: "Rejeitar",
        REJEITADO: "Rejeitado",
        RECEBIDO: "Recebido",
        PENDENTE: "Pendente",
        ERROGERARBOLETO: "Não é possível gerar boletos",
        LISTAEXAMES: "Lista de Exames",
        GUIA: "Guia",
        ITEM: "Item",
        EXAME: "Exame",
        REMOVIDO: "Removido",
        PRECO: "Preço",
        GLOSARGUIA: "Glosar guia",
        INCLUIRGUIA: "Incluir guia",
        GLOSAS: "Glosas",
        TOTAL: "Total",
        SALVAR: "Salvar",
        CONVENIO: "Convênio",
    },
    LOTESCONTARECEBERLISTAR:{
        PLACEHOLDERPROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        DESCRICAO: "Descrição",
        DATAINICIAL: "Data Inicial",
        DATAFINAL: "Data Final",
        QTDCONTAS: "Qtde. Contas",
        ID: "Id",
        DATA: "Data",
        CONTACORRENTE: "Conta Corrente",
        VALOR: "Valor",
        TITULO: "Lote de Contas a Receber"
    },
    LOTESCONTARECEBERCADASTRO:{
        TITULO: "Contas a Receber",
        CONVENIO: "Convênio",
        DESCRICAO: "Descrição",
        DATAINICIAL: "Data Inicial",
        PLACEHOLDERSELECIONEUMADATA: "Selecione uma Data",
        DATAFINAL: "Data Final",
        CONTACORRENTE: "Conta Corrente",
        ESPECIE: "Espécie",
        OBSERVACAO: "Observação",
        LOCALIZARCONTAS: "Localizar Contas",
        CONTAS: "Contas",
        DOCUMENTO: "Documento",
        NOTAFISCAL: "Nota Fiscal",
        EMISSAO: "Emissão",
        VENCIMENTO: "Vencimento",
        VALOR: "Valor",
        SITUACAO: "Situação",
        PENDENTE: "Pendente",
        PAGO: "Pago",
        CANCELADO: "Cancelado",
        BAIXAR: "Baixar",
        CANCELARLOTE: "Cancelar Lote",
        ARQUIVOS: "Arquivos",
        DESCONTO: "Desconto",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ENVIADO: "E-mail enviado?",
        ENVIAREMAIL: "Enviar e-mail",
        FECHARFATURA: "Fechar Fatura?",
        EMITIRNOTAFISCAL: "Emitir Nota Fiscal",
        CONSULTARNOTAFISCAL: "Consultar Nota Fiscal",
        EMITIRLOTE: "Lote de notas enviado para emissão, isso pode levar alguns minutos.",
        CONSULTARLOTE: "Consulta solicitada, isso pode levar alguns minutos.",
        FECHARFATURAMSG: "Deseja realmente fechar a fatura? Você não poderá fazer alterações após essa ação.",
    },
    CONTASPAGARLISTAR: {
        PLACEHOLDERPROCURARPORNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        DATA: "Data",
        DESCRICAO: "Descrição",
        PEDIDO: "Pedido",
        CENTROCUSTO: "Centro de Custo",
        TITULO: "Contas a Pagar",
        FORNECEDOR: "Fornecedor",
    },
    CONTASPAGARCADASTRO: {
        DATA: "Data",
        DESCRICAO: "Descrição",
        PEDIDO: "Pedido",
        CENTROCUSTO: "Centro de Custo",
        PLACEHOLDERCENTROCUSTO: "Selecione o Centro do Custo",
        PLACEHOLDERDATA: "Selecione uma data",
        NOTAFISCAL: "Nota Fiscal",
        FORNECEDOR: "Fornecedor",
        PLACEHOLDERFORNECEDOR: "Selecione o Fornecedor",
        CONTACORRENTE: "Conta Corrente",
        PLACEHOLDERCONTACORRENTE: "Selecione a Conta Corrente",
        UNIDADE: "Unidade",
        FUNCIONARIO: "Funcionário",
        OBSERVACAO: "Observações",
        PARCELAS: "Parcelas",
        VENCIMENTO: "Vencimento",
        VALORPARCIAL: "Valor Parcial",
        JUROS: "Juros",
        MULTA: "Multa",
        TOTAL: "Total",
        SITUACAO: "Situação",
        DEFINIRCOMORECEBIDO: "Clique para definir como recebido",
        CLIQUEPARACANCELAR: "Clique para cancelar",
        RECEBIDO: "Recebido",
        CANCELADO: "Cancelado",
        MOTIVOCANCELAMENTO: "Motivo de Cancelamento",
        PAGOEM: "Pago em",
        ESPECIE: "Espécie",
        PLACEHOLDERESPECIE: "Selecione a Espécie",
        CARTAO: "Cartão",
        CHEQUENUMERO: "Cheque Numero",
        ADICIONARPARCELA: "Adicionar Parcela",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        PAGO: "Pago em",
        CAMPOESPECIEOBRIGATORIO: "Campo 'Espécie' obrigatório!",
        ATENCAO: "Atenção!",
    },
    LOTECONTASPAGARLISTAR: {
        PLACEHOLDERPROCURARPORNOME: "Procurar por nome",
        PLACEHOLDERDATA: "Selecione uma data",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        DESCRICAO: "Descrição",
        CONTACORRENTE: "Conta Corrente",
        DATAINICIAL: "Data Inicial",
        DATAFINAL: "Data Final",
        TITULO: "Lotes de Contas a Pagar",
        TIPOBAIXA: "Tipo de Baixa",
        QTDCONTAS: "Qtde. Contas",
        VALOR: "Valor",
        PDF: "PDF"
    },
    LOTECONTASPAGARCADASTRO: {
        DESCRICAO: "Descrição",
        CONTACORRENTE: "Conta Corrente",
        PLACEHOLDERDATA: "Selecione uma data",
        DATAINICIAL: "Data Inicial",
        DATAFINAL: "Data Final",
        TITULO: "Lotes de Contas a Pagar",
        TIPOBAIXA: "Tipo de Baixa",
        OBSERVACAO: "Observações",
        LOCALIZARCONTAS: "Localizar Contas",
        CONTAS: "Contas",
        DOCUMENTO: "Documento",
        FORNECEDOR: "Fornecedor",
        NOTAFISCAL: "Nota Fiscal",
        EMISSAO: "Emissão",
        VENCIMENTO: "Vencimento",
        VALOR: "Valor",
        SITUACAO: "Situação",
        PENDENTE: "PENDENTE",
        PAGO: "PAGO",
        CANCELADO: "CANCELADO",
        BAIXAR: "Baixar",
        BANDEIRA: "Bandeira",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    CONTASCORRENTESLISTAR: {
        PLACEHOLDERPROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        BANCO: "Banco",
        AGENCIA: "Agência",
        NUMERO: "Número",
        TITULO: "Contas Correntes"
    },
    CONTASCORRENTESCADASTRO: {
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        BANCO: "Banco",
        AGENCIA: "Agência",
        NUMERO: "Número",
        CODIGOCEDENTE: "Código Cedente",
        CNPJ: "CNPJ",
        NOSSONUMERO: "Nosso Número",
        CARTEIRA: "Carteira",
        SERVICONOTAFISCAL: "Serviço de Nota Fiscal",
        EXTRATO: "Extrato",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    CENTROCUSTOLISTAR: {
        PLACEHOLDERPROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        TITULO: "Centros de Custos"
    },
    CENTROCUSTOCADASTRO: {
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    BANDEIRALISTAR: {
        PLACEHOLDERPROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        TITULO: "Bandeiras"
    },
    BANDEIRACADASTRO: {
        NOME: "Nome",
        TAXACREDITO: "Taxa Crédito",
        TAXADEBITO: "Taxa Débito",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    FECHAMENTOCAIXA: {
        GUIA: "Guia",
        DATAEMISSAO: "Data de Emissão",
        LOCALORIGEM: "Local de Origem",
        UNIDADE: "Unidade",
        FUNCIONARIO: "Funcionário",
        VALOR: "Valor (R$)",
        DESCONTO: "Desconto (R$)",
        DEBITO: "Débito (R$)",
        RECEBER: "Receber",
        GUIASFATURADAS: "Guias Faturadas",
        QUANTIDADE: "Quantidade",
        GUIASNAOFATURADAS: "Guias Não Faturadas",
        TITULO: "Fechamento de Caixa"
    },
    RELACAODEBITOS: {
        SOMENTEPARCELASVENCIDAS: "Somente Parcelas Vencidas",
        CODIGO: "Código",
        DATAVENCIMENTO: "Data de Vencimento",
        VALOR: "Valor (R$)",
        TOTAL: "Total (R$)",
        TOTALPACIENTE: "Total de Paciente",
        TITULO: "Relação de Débitos"
    },
    ESTATISTICAS: {
        PRINCIPAIS: "Principais",
        GERAL: "Geral",
        SETORES: "Setores",
        FATURAMENTO: "Faturamento",
        VALOR: "Valor (R$)",
        ATENDIMENTOS: "Atendimentos",
        GUIAS: "Guias",
        PACIENTESNOVOS: "Pacientes",
        EXAMES: "Exames",
        FLUXOCAIXA: "Fluxo de Caixa",
        RECEBIMENTOS: "Recebimentos",
        PAGAMENTOS: "Pagamentos",
        LUCRO: "Lucro",
        TITULO: "Estatísticas"
    },
    FATURAR: {
        FILTRO: "Filtro",
        UNIDADE: "Unidade",
        LOCALORIGEM: "Local de Origem",
        TIPOCONVENIO: "Tipo de Convênio",
        SETOR: "Setor",
        DEPARTAMENTO: "Departamento",
        EMISSAO: "Emissão",
        SOLICITACAO: "Solicitação",
        ALTA: "Alta",
        DIGITACAO: "Digitação",
        IMPRESSAO: "Impressão",
        COLETA: "Coleta",
        ASSINATURA: "Assinatura",
        INCLUIRCONVENIOSPARTICULARES: "Incluir convênios particulares",
        GUIACONFERIDA: "Guia conferida",
        SELECIONADOS: "Selecionados",
        GUIA: "Guia",
        REFERENCIA: "Referência",
        INCLUIR: "Incluir",
        AGENDAMENTOS: "Agendamentos",
        ORDENARPOR: "Ordenar Por",
        DATAEMISSAO: "Data de emissão",
        DATASOLICITACAO: "Data de solicitação",
        DATANASCIMENTO: "Data de Nascimento",
        NUMEROCONSELHO: "Número do Conselho",
        CNSEMVEZDEINSCRICAO: "CNS em vez de Inscrição",
        SOMENTECOMMATERIALENTREGUE: "Somente com Material Entregue",
        SOMENTECOMRESULTADOASSINADO: "Somente com Resultado Assinado",
        AGRUPARELATROPIOPORSETOR: "Agrupa Relatório por Setor",
        SOMENTEJAFATURADOS: "Somente já faturados",
        ESTUDOCLINICO: "Estudo Clínico",
        CRIARDOCUMENTO: "Criar documento",
        GERARPDF: "Gerar PDF",
        GERARXLS: "Gerar XLS",
        CRIARAGENDAMENTO: "Criar agendamento",
        VOLTAR: "Voltar",
        TITULO: "Faturar"
    },
    MANUTENCAOLISTAR: {
        PESSOAS: "Pessoas",
        PACIENTES: "Pacientes",
        LISTARTODOS: "Listar todos",
        INCLUIR: "Incluir",
        ESPECIE: "Espécies",
        RACA: "Raças",
        MEDICOS: "Solicitantes",
        FUNCIONARIO: "Funcionários",
        NIVELACESSO: "Níveis de acesso",
        EMPRESA: "Empresas",
        TIPOCONVENIO: "Tipos de convênios",
        FORNECEDOR: "Fornecedores",
        EXAMES: "Exames",
        MATERIAIS: "Materiais",
        CONSERVANTES: "Conservantes",
        ABREVIATURAS: "Abreviaturas",
        GRUPOEXAME: "Grupos de exames",
        TIPOCODIGO: "Tipo do código de exame",
        SETORES: "Setores",
        DEPARTAMENTOS: "Departamentos",
        TABELASPRECO: "Tabelas de preços",
        VALORES: "Valores CBHPM",
        MOTIVOS: "Motivos",
        LIBERACAOAUTO: "Liberação Automática",
        CONFIGURAR: "Configurar",
        OUTROS: "Outros",
        UNIDADES: "Unidades",
        LOCAIS: "Locais",
        TIPOSLOCAIS: "Tipos de locais",
        TIPOAGENDAMENTO: "Tipos de agendamento",
        PESQUISAS: "Pesquisas",
        FERIADO: "Feriados",
        CONDPAGAMENTO: "Condições de pagamento",
        ESPECIEPAGAMENTO: "Espécie de pagamento",
        SOROTECA: "Soroteca - Geladeiras",
        CONTAINERS: "Soroteca - Containers",
        CONFIGTISS: "Configuração TISS",
        CONFIGAGENDAMENTO: "Configurações de agendamentos",
        SERVICOPERIODO: "Serviços executados periodicamente",
        ALTERAR: "Alterar",
        PROCURAR: "Procurar",
        REDUCAOACRESCIMO: "Redução/Acréscimo",
        LAUDODESCRITIVO: "Modelo de Laudos Descritivos",
        AMOSTRASSTATUS: "Amostra Status",
        REPASSEMEDICO: "Repasse Médico"
    },
    PACIENTESLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        UNIRCADASTRO: "Unificar cadastros",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        SEXO: "Sexo",
        NASCIMENTO: "Nascimento",
        EMAIL: "E-mail",
        RESPONSAVEL: "Responsável",
        DESATIVADO: "Desativado",
        EDITAR: "Editar",
        MANUTENCAO: "Manutencao",
        ESPECIE: "Espécie",
        RACA: "Raça",
    },
    PACIENTECADASTRO: {
        PENDENCIA: "Sem pendências financeiras",
        NOME: "Nome",
        NOMESOCIAL: "Nome Social",
        ANIMAL: "Animal",
        FONE: "Fone",
        CELULAR: "Celular",
        ENVIAMSG: "Envia mensagens SMS",
        SEXO: "Sexo",
        SELECIONESEXO: "Selecione o sexo",
        FEM: "Feminino",
        MASC: "Masculino",
        INDT: "Indeterminado",
        NASCIMENTO: "Nascimento",
        IDADE: "Idade",
        CALCIDADE: "Calcular Idade em",
        ANOS: "Anos",
        MES: "Meses",
        DIA: "Dias",
        PESO: "Peso (kg)",
        ALTURA: "Altura (cm)",
        EMAIL: "Email",
        RG: "RG",
        CPF: "CPF",
        CARTAOSAUDE: "Cartão Nacional de Saúde",
        PLANO: "Plano",
        TITULAR: "Titular",
        PASSAPORTE: "Passaporte",
        INSCRICAO: "Inscrição",
        DV: "D.V.",
        VALIDADE: "Validade",
        CONVPADRAO: "Convênio Padrão",
        RACA: "Raça",
        ESPECIE: "Espécie",
        NOMEMAE: "Nome da Mãe",
        RESPONSAVEL: "Responsável",
        CADASTRORES: "Cadastrar Responsável",
        OBSERVACOES: "Observações",
        USUARIO: "Usuário",
        CAPTURAR: "Clique para capturar pela webcam",
        CAMERA: "Câmera",
        DESATIVADO: "Desativado",
        CRIARUSUARIO: "Criar Usuário web",
        CANCELARUSUARIO: "Cancelar Usuário Web",
        GUIAS: "Guias",
        LISTAR: "Listar",
        DOC: "Documentos",
        NENHUMDOC: "Nenhum documento",
        PUBRESULTADOS: "Publicar resultados",
        CHAVE: "Chave",
        INDICACAO: "Indicação",
        CODINTEG: "Códigos integrações",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCODIGO: "Adicionar código",
        GUIAOPERADORA: "Buscar guia na operadora",
        GUIAOP: "Guia na Operadora",
        BUSCA: "Busca",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONVOBRIGATORIO: "Convênio padrão obrigatório.",
        NUMGUIA: "Número da guia na operadora obrigatório.",
        NASCIMENTOINVALIDO: "Valor informado para a data de nascimento inválido!",
        IDADEINVALIDA: "Valor informado para a idade inválido!",
        EMAILMSG: "O e-mail ",
        EMUSO: " já está sendo usado pelo usuario do ",
        RACAOBGR: "Raça obrigatória.",
        ESPECIEOBGR: "Espécie obrigatória.",
        ESPECIEINVALIDA: "Especie inválida para esta raça.",
        RESPOBRG: "Responsável obrigatório.",
        CPFOBRG: "CPF ou passaporte obrigatório.",
        ENDOBRG: "Endereço obrigatório.",
        EMAILOBRG: "Email obrigatório.",
        CELOBRG: "Celular obrigatório.",
        FALHAUSUARIO: "Não é possível criar usuário web sem email.",
        CPFUSO: "Este CPF já está em uso.",
        ERROSALVARPACIENTE: "Erro ao salvar paciente, verificando cpf: ",
        ERROPACIENTE: "Erro ao salvar paciente: ",
        ERROHIST: "Erro ao carregar histórico: ",
        AGENDAMENTOS: "Agendamentos",
        RESPONSAVELOBRG: "Responsável obrigatório.",
    },
    MODALSMS: {
        ENVIARMENSAGEM: "Enviar mensagem SMS",
        NUMERO: "Número",
        MENSAGEM: "Mensagem",
    },
    ESPECIESLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        MANUTENCAO: "Manutenção",
        ESPECIE: "Espécies",
    },
    ESPECIECADASTRO: {
        SALVANDOESPECIE: "A espécie está sendo salva, este processo pode levar alguns segundos.",
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        ESPECIE: "Espécie",
    },
    RACALISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        RACAS: "Raças",
        MANUTENCAO: "Manutenção",
    },
    RACACADASTRO: {
        SALVANDORACA: "A raça está sendo salva, este processo pode levar alguns segundos.",
        NOME: "Nome",
        ESPECIE: "Espécie",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    MEDICOS: {
        PROCURARNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        UNIFICARCADASTROS: "Unificar cadastros",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        CONSELHO: "Conselho",
        ID: "Id",
        CBO: "CBO-S",
        EMAIL: "E-mail",
        FONE: "Fone",
        DESABILITADO: "Desabilitado",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
    },
    MEDICOCADASTRO: {
        NOME: "Nome",
        FONE: "Fone",
        CEL: "Celular",
        DATA: "Data de nascimento",
        IDADE: "Idade",
        CPF: "CPF",
        CONSELHO: "Conselho",
        UF: "UF",
        NUMEROCONSELHO: "Número no Conselho",
        CODOPERADORA: "Código na Operadora",
        CNES: "CNES",
        CBOS: "CBO-S",
        CONVPADRAO: "Convênio Padrão",
        EMAIL: "Email",
        OBSERVACAO: "Observações",
        FOTO: "Foto",
        SELECT: "Select Device",
        CAPTURAR: "Clique para capturar pela webcam",
        ESCOLHER: "Escolher arquivo...",
        CRIARUSUARIO: "Criar Usuário web",
        CANCELAUSUARIO: "Cancelar Usuário Web",
        GUIAS: "Guias",
        LISTAR: "Listar",
        PUBLICAR: "Publicar resultados",
        CODIGOSINTEGRACAO: "Códigos integrações",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEGRADOR: "Integrador",
        ADDCODIGO: "Adicionar código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONVOBR: "Convênio padrão obrigatório.",
        PREENCHEREMAIL: "Para criar um usuário web é necessário preencher o e-mail",
        ERROSALVARMEDICO: "Erro ao salvar solicitante: ",
        OEMAIL: "O e-mail ",
        EMUSO: "já está sendo usado pelo usuario do ",
    },
    FUNCIONARIOLISTA: {
        PROCURARNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        NIVELACESSO: "Nível de Acesso",
        EMAIL: "E-mail",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        FUNCIONARIO: "Funcionários",
    },
    FUNCIONARIOCADASTRO: {
        UNIDADE: "Unidade",
        USUARIO: "Usuário",
        ALTERAR: "Alterar",
        SENHA: "Senha",
        CONFIRMARSENHA: "Confirmação de Senha",
        CPF: "CPF",
        CONSELHO: "Conselho",
        UF: "UF",
        NUMCONSELHO: "Número no Conselho",
        FONE: "Fone",
        CELULAR: "Celular",
        DATA: "Data de nascimento",
        IDADE: "Idade",
        NIVEL: "Nível",
        EMAIL: "Email",
        IDENTIFICADOR: "Identificador",
        ASSINATURA: "Assinatura Texto",
        FOTO: "Foto",
        CAPTURAR: "Clique para capturar pela webcam",
        ARQUIVO: "Escolher arquivo...",
        DESATIVADO: "Desativado",
        ASS: "Assinatura",
        ESCOLHERARQ: "Escolher Arquivo",
        MUDARSENHA: "Redefinir Senha",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        FUNCIONARIO: "Funcionário",
        SENHASIGUAIS: "As senhas devem ser iguais",
        USUARIOLOGADO: "Não é possível alterar o usuário atualmente logado!",
        ALTERARUSUARIO: "Tem certeza que deseja alterar o login desse funcionário?",
    },
    NIVELDEACESSO: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        MANUTENCAO: "Manutenção",
        NIVEL: "Níveis de acesso",
    },
    NIVELDEACESSOCADASTRO: {
        SALVANDONIVEL: "O nível está sendo salvo, este processo pode levar alguns segundos.",
        NOME: "Nome",
        ACESSALOCAIS: "Acessa Somente Tipos de Locais",
        ID: "Id",
        SELECIONALOCAL: "Selecione o tipo de local",
        ADICIONALOCAL: "Adicionar Tipo de Local",
        ADM: "Administrador",
        RADIOLOGISTA: "Radiologista",
        ACESSOINTERNET: "Permite acesso pela internet",
        REGRAS: "Regras",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        NIVEL: "Nível de Acesso",
    },
    CONVENIOS: {
        PROCURAR: "Procurar por nome",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        CODIGO: "Código",
        NOME: "Nome",
        TIPOCONV: "Tipo Convênio",
        TABPRECO: "Tabela de Preço",
        PARTICULAR: "Particular",
        REEMBOLSO: "Reembolso",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
    },
    CONVENIOCADASTRO: {
        NOME: "Nome",
        CONTATO: "Contato",
        FONE: "Fone",
        CNPJ: "CNPJ",
        INSCRICAO: "Inscrição estadual",
        INSCRICAOMUN: "Inscrição municipal",
        TABPRECO: "Tabela de preço",
        TABPRECOINFO: "Tabela de preço dos exames",
        DESCONTOPORTE: "Desconto no porte",
        DESCONTOMAX: "Desconto máximo",
        IRRF: "IRRF",
        DESCONTO: "Desconto",
        ANS: "Registro ANS",
        CODLAB: "Código laboratório",
        TIPOCONV: "Tipo de convênio",
        TISS: "Tabela TISS",
        EMAIL: "Email",
        MODELO: "Modelo de resultado",
        MODELOCOMPROVANTE: "Modelo de comprovante",
        REPRESENTANTE: "Representante",
        TABELACOPART: "Tabela de coparticipação",
        TABELACOPARTINFO: "Tabela de preço de coparticipação",
        TABELAURGENTE: "Tabela de urgência",
        TABELAURGENTEINFO: "Tabela de preço para exames de urgência",
        INFOTABELAPRECO:"Abrir informações da tabela de preço",
        RAZAOSOCIAL: "Razão Social",
        RESPLEGAL: "Responsável legal",
        RESPTEC: "Responsável técnico",
        CPFRESP: "CPF do responsável técnico",
        REGPROF: "Registro profissional",
        TELRESP: "Telefone responsável técnico",
        CONTATORESULTADO: "Contato para resultado",
        TELEFONECONTATO: "Telefone do contato para resultado",
        EMAILCONTATO: "E-mail do contato para resultado",
        PRAZOPAGAMENTO: "Prazo de pagamento em dias",
        SISTEMAUTILIZADO: "Sistema utilizado",
        RESPFINANCEIRO: "Responsável financeiro",
        TEMPODESLOCAMENTO: "Tempo de deslocamento",
        LIMITEAMOSTRA: "Limite para receber amostra sem alerta",
        NUMEROSADT: "Numero exames SADT",
        UNIDADE: "Unidade",
        OBSERVACOES: "Observações",
        ACESSORESULTADO: "Conveniados não têm acesso aos resultados",
        HABILITASADT: "Habilitar guias SADT",
        PODEINCLUIR: "Pode incluir guia via internet",
        NAOAGRUPAR: "Não agrupar exames em amostras existentes",
        EXIBIROBSERVACAO: "Exibir observação na guia",
        VERAMOSTRA: "Não ver amostras integracao",
        GERAETIQUETA: "Gera etiqueta pré-coleta",
        ENVIAANEXO: "Envia anexo da guia na integração",
        DESATIVADO: "Desativado",
        ESCOLHEMEDICO: "Permite escolher solicitantes no portal",
        GUIALIBERADA: "Visualizar apenas resultados de guias completas",
        USUARIOS: "Usuários",
        CANCELARUSUARIOS: "Cancelar usuário Web Principal",
        CRIARUSUARIO: "Criar usuário web",
        GERENCIAUSUARIO: "Gerenciar Usuários Secundários",
        GUIAS: "Guias",
        LISTARTABELA: "Listar a tabela de preços dos exames para este convênio",
        LISTARGUIAS: "Listar Guias",
        PUBLICARRESULTADO: "Publicar resultados",
        HABILITARESTOQUE: "Habilitar estoque",
        CODINTEGRACAO: "Códigos integrações",
        CHAVEINTERNET: "Senha de integração",
        TIPO: "Tipo",
        CODIGO: "Código",
        ADDCODIGO: "Adicionar código",
        TISSCADASTRO: "TISS",
        VERSAO: "Versão",
        URLWEB: "URL webservices",
        LOGIN: "Login",
        SENHA: "Senha",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        ERROSALVAR: "Erro ao salvar convênio: ",
        OEMAIL: "O e-mail ",
        EMUSO: "já está sendo usado pelo usuario do ",
        ERROUSUARIO: "Erro ao excluir usuario: ",
        ETIQUETAPRECOLETA: "Etiqueta pré-coleta",
        QUANTIDADEETIQUETA: "Quantas etiquetas deverão ser geradas ?",
        QUANTIDADE: "Quantidade",
        IMPRIMIRETIQUETA: "Impressão de etiqueta solicitada.",
        ENDERECORECOLHIMENTO: "Endereço de recolhimento",
        HORARIOROTA: "Horário da rota",
        RESPONSAVELTRIAGEM: "Responsável pela triagem",
        INSCRICAOOBRIGATORIA: "Tornar campo de inscrição do paciente obrigatório",
        EXIBEFATURA: "Exibe faturas",
        CONDICAO: "Condição de pagamento",
    },
    TIPOCONVENIO: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        TIPOCONV: "Tipos de convênios",
    },
    TIPOCONVENIOCADASTRO: {
        SALVANDOTIPO: "O tipo de convênio está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        PRAZOPENDENTE: "Prazo Pendente",
        PRAZOANALISE: "Prazo Análise",
        TIPOCODIGO: "Tipo de Código",
        SELECIONETIPO: "Selecione o Tipo",
        REEMBOLSO: "Reembolso",
        PARTICULAR: "Particular",
        FATURAABERTA: "Fatura aberta",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        TIPOCONV: "Tipos de convênios",
        CONVPADRAO: "Convênio Padrão"
    },
    FORNECEDORES: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        CONTATO: "Contato",
        TELEFONE: "Telefone",
        MANUTENCAO: "Manutenção",
        FORNECEDOR: "Fornecedores",
    },
    FORNECEDORCADASTRO: {
        CONTATO: "Contato",
        TELEFONE: "Telefone",
        CNPJ: "CNPJ",
        IE: "Inscrição Estadual",
        IM: "Inscrição Municipal",
        EMAIL: "Email",
        OBS: "Observações",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        FORNECEDOR: "Fornecedor",
        CAMPOOBRIGATORIO: "O campo cidade é obrigatório",
        ERROSALVAR: "Erro ao salvar fornecedor: ",
    },
    EXAMESLISTA: {
        PROCURARPOR: "Procurar por ",
        APELIDO: "Apelido",
        NOME: "Nome",
        SINONIMIA: "Sinonimia",
        SETOR: "Setor",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        ADICIONAL: "adicional",
        EDITAR: "Editar",
        SELECIONECOD: "Selecione um Código",
        MANUTENCAO: "Manutenção",
        EXAMES: "Exames",
    },
    EXAMECADASTRO: {
        APELIDO: "Apelido",
        NOME: "Nome",
        SETOR: "Setor",
        GEN: "Gênero",
        MASC: "Masculino",
        FEM: "Feminino",
        ETQNUMERO: "Etiqueta número",
        CLIQUESETA: "Clique na seta para buscar o próximo valor não utilizado",
        ORDEM: "Ordem no resultado",
        QTDEAMOSTRA: "Qtde. amostras",
        QTDEETQ: "Qtde. etiquetas",
        PRAZODIA: "Prazo dias",
        PRAZOHORA: "Prazo horas",
        PRAZOMIN: "Prazo minutos",
        MINJEJUM: "Tempo mínimo de jejum",
        VOLDESEJADO: "Volume desejado",
        VOLMIN: "Volume mínimo",
        SINONIMIA: "Sinonímia",
        ULTIMOSRESUL: "Qtde. de Ultimos Resultados",
        RECIP: "Recipientes",
        DOMINGO: "Domingo",
        SEGUNDA: "Segunda",
        TERCA: "Terça",
        QUARTA: "Quarta",
        QUINTA: "Quinta",
        SEXTA: "Sexta",
        SABADO: "Sábado",
        OBTAMOSTRA: "Obtenção de amostra",
        INFOLAB: "Informações ao laboratório",
        INFOPACIENTE: "Informações ao paciente",
        RASBANCADA: "Rascunho de bancada",
        POP: "POP",
        APLICLINICA: "Aplicação clínica / Interpretação",
        CRITREJEICAO: "Critérios de rejeição",
        CONSEXAME: "Consumiveis do exame",
        PESCLINICA: "Pesquisa clínica",
        EXACOMPLEMENTAR: "Exames complementares",
        TABPRECO: "Tabelas de preços",
        BLOQEXA: "Bloquear exame",
        DESBLOQEXA: "Desbloquear exame",
        VAL: "Valor",
        BLOQ: "Bloqueado",
        BLOQMOTIVO: "Motivo do bloqueio",
        AUT: "Autorização",
        NEGOCIADO: "Negociado",
        VERSAOMASC: "Seleciona versão da máscara ao digitar o resultado",
        NAOPUBLICA: "Não publica resultados na internet",
        EMPLANTAO: "Realizado em caráter de plantão",
        EXAFATU: "Exame de faturamento",
        EXAPERFIL: "Exame de perfil",
        INFOMED: "Informar uso medicamento",
        INFODUM: "Informar data última menstruação",
        INFOPESO: "Informar peso do paciente",
        INFOALTURA: "Informar altura do paciente",
        LIBERAPARCIAL: "Liberar resultado parcial",
        BLOQREPETIDO: "Bloqueio de exames repetidos",
        DESATIVADO: "Desativado",
        LIBAUTO: "Editar liberação automática",
        EDITARMASCARA: "Editar máscara de resultado",
        CODINTEG: "Códigos integrações",
        TIPO: "Tipo",
        CODIGO: "Código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        EXAME: "Exame",
        APELIDOEXISTE: "O apelido já existe, tente outro por favor.",
        OUTRASDESPESAS: "Exame de Outras Despesas",
        EXAMEALIQUOTA: "Exame para Aliquotagem",
        CODIGOTABELA: "Código Tabela",
        CODIGODESPESA: "Código Despesa",
        ALERTACAMPOAPELIDO: "O campo apelido deve ser preenchido",
        ALERTACAMPONOME:"O campo nome deve ser preenchido", 
        LAUDORESTRITO: "Laudo Restrito",       
    },
    MATERIAISLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        MATERIAIS: "Materiais",
    },
    MATERIALCADASTRO: {
        SALVANDOMATERIAL: "O material está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        CODINTEG: "Códigos integrações",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCOD: "Adicionar código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        MATERIAL: "Material",
    },
    CONSERVANTESLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        MANUTENCAO: "Manutenção",
        CONSERVANTES: "Conservantes",
    },
    CONSERVANTECADASTRO: {
        NOME: "Nome",
        CODINTEG: "Códigos integrações",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCODIGO: "Adicionar código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONSERVANTE: "Conservante",
        ERROSALVAR: "Erro ao salvar conservante: ",
    },
    ABREVIATURAS: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        MANUTENCAO: "Manutenção",
        ABREVIATURAS: "Abreviaturas",
    },
    ABREVIATURACADASTRO: {
        CODIGO: "Código",
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        DESATIVADO:"Desativado",
        MANUTENCAO: "Manutenção",
        ABREVIATURA: "Abreviatura",
    },
    GRUPOSEXAMELISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        EXAME: "Exames",
        MANUTENCAO: "Manutenção",
        GRUPOEXAME: "Grupo de Exames",
    },
    GRUPOSEXAMECADASTRO: {
        NOME: "Nome",
        EXAME: "Exames",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        GRUPOEXAME: "Grupo de Exames",
        ERROSALVAR: "Erro ao salvar grupo de exames: ",
    },
    BUSCAEXAME: {
        APELIDO: "Apelido",
        NOME: "Nome",
        SETOR: "Setor",
        RECIPIENTES: "Recipiente",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        INCLUIR: "Incluir",
    },
    BUSCACONVENIO: {
        ID: "Id",
        NOME: "Nome",
        INCLUIR: "Incluir",
    },
    TIPOCODIGOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        TIPOSCODIGO: "Tipos de código de exame",
    },
    TIPOCODIGOCADASTRO: {
        SALVANDOTIPO: "O tipo de código de exame está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        TIPOSCODIGO: "Tipos de código de exame",
    },
    SETORESLISTAR: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        SETORES: "Setores",
    },
    SETORESCADASTRO: {
        SALVARSETOR: "O setor está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        AGRUPASETOR: "Agrupar com setor",
        SELECIONASETOR: "Selecione o Setor",
        DEPTO: "Departamento",
        SELEDEPTO: "Selecione o Departamento",
        MODELETQ: "Modelo de etiqueta",
        SELECMODEL: "Selecione o Modelo",
        QTDEEXAME: "Quantidade de exames por amostra",
        COR: "Cor",
        INTEG: "Integradores",
        ID: "Id",
        SELECINTEG: "Selecione o integrador",
        ADDINTEG: "Adicionar integrador",
        SETOR: "Setor",
        NAOBANCADA: "Não emite bancada",
        COMRASCUNHO: "Emite bancada com rascunho",
        NAOCRIA: "Não cria amostras automaticamente na recepção",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        SETORES: "Setor",
    },
    DEPTOLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        MANUTENCAO: "Manutenção",
        DEPTOS: "Departamentos",
    },
    DEPTOCADASTRO: {
        NOME: "Nome",
        FATURA: "Fatura",
        CRIAFATURA: "Cria as faturas separadamente",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    TABPRECOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        TIPO: "Tipo",
        MANUTENCAO: "Manutenção",
        TABPRECO: "Tabelas de Preços",
    },
    TABPRECOCADASTRO: {
        SALVARTAB: "A tabela de preços está sendo salva, este processo pode levar alguns minutos.",
        NOME: "Nome",
        TIPO: "Tipo",
        SELTIPO: "Selecione o tipo",
        PROCURAPOR: "Procurar por ",
        APELIDO: "Apelido",
        CODINTEG: "Códigos de Integrações",
        REAJUSTEVAL: "Reajustar valores",
        BLOQEXA: "Bloquear Todos Exames ? ",
        APENASBLOQ: "Exibir Apenas Bloqueados",
        PERCENTUAL: "Qual o percentual que deseja alterar nos valores da tabela?",
        SIM: "Sim",
        NAO: "Não",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        VAL: "Valor",
        QTDEUS: "Qtde. US",
        PERCENTUALPORTE: "PercentualPorte",
        CUSTOOP: "CustoOperacional",
        VALCBHPM: "ValorCBHPM",
        BLOQ: "Bloqueado",
        MOTIVOBLOQ: "Motivo do bloqueio",
        AUT: "Autorização",
        NEGOCIADO: "Negociado",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        TABPRECO: "Tabelas de Preços",
        DESATIVAR: "Desativar",
        FILME: "Qtde. Filme"
    },
    VALCBHPM: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        VAL: "Valor",
        MANUTENCAO: "Manutenção",
        VALCB: "Valores CBHPM",
    },
    VALCBHPMCADASTRO: {
        CODIGO: "Código",
        VAL: "Valor",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        VALCB: "Valores CBHPM",
        ERROSALVAR: "Erro ao salvar valor cbhpm: ",
    },
    MOTIVOSLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        DESCINTERNA: "Descrição Interna",
        DESEXTERNA: "Descrição Externa",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        MOTIVOS: "Motivos",
    },
    MOTIVOCADASTRO: {
        DESCINTERNA: "Descrição Interna",
        DESEXTERNA: "Descrição Externa",
        DESTINO: "Destinos",
        ENVIAEMAIL: "Envia E-mail",
        NOPORTAL: "Aparece no Portal",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        MOTIVOS: "Motivos",
        ERROSALVAR: "Erro ao salvar motivo: ",
    },
    LIBERACAOAUTOLISTA: {
        PROCURARPOR: "Procurar por Apelido do Exame",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        APELIDO: "Apelido",
        MANUTENCAO: "Manutenção",
        LIBAUTO: "Liberação Automática",
    },
    LIBERACAOAUTOCADASTRO: {
        FILTROS: "Filtros",
        VERSAOMASC: "Versão da Máscara",
        SELECVERSAO: "Selecione uma versão para filtrar",
        SELECCAMPO: "Selecione um campo para filtrar",
        CARREGARREF: "Carregar valores de Referência",
        SEMREF: "Não existem valores de referêcia configurados para esse campo",
        EXPRESSAO: "Expressão",
        VALINICIAL: "Valor Inicial",
        VALFINAL: "Valor Final",
        IMPORTAR: "Importar",
        LIBAUTOMATICA: "Liberação Automática",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        LIBAUTO: "Liberação Automática",
        ERROSALVAR: "Erro ao salvar configuração: ",
        ERROREGISTRO: "Ocorreu um erro ao importar os registros",
        NAOENCONSTRADO: "Nenhum exame com esse apelido foi encontrado.",
    },
    LIBERACAOAUTOMANUTENCAO: {
        CODIGO: "Código",
        MASCARA: "Máscara",
        CAMPO: "Campo",
        SEXO: "Sexo",
        IDADEMAIOR: "Idade > Que",
        IDADEMENOR: "Idade < Que",
        VALMIN: "Valor Mínimo (>)",
        VALMAX: "Valor Máximo (<=)",
        CAMPOUN: "Campo Único",
        HABILITADA: "Habilitada",
        PROXLIB: "Próxima Liberação",
        PRIMEIROCAMPO: "Primeiro Campo",
        ULTIMOCAMPO: "Último Campo",
        INCLUIR: "Incluir",
        SUCESSO: "Filtros importados com sucesso",
    },
    REDUCAOACRESCIMOCADASTRO: {
        MANUTENCAO: "Manutenção",
        REDUCAOACRESCIMO: "Redução/Acréscimo",
        NOME: "Nome",
        PRIMEIROEXAME: "Primeiro Exame",
        SEGUNDOEXAME: "Segundo Exame",
        TERCEIROEXAME: "Terceiro Exame",
        DEMAIS: "Demais Exames",
        SETOR: "Setor",
        TABELA: "Tabela",
        CH: "CH",
        CO: "CO",
        PORTE: "Porte",
        FILME: "Filme",
        ERROSALVAR: "Erro ao salvar "
    },
    REDUCAOACRESCIMOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
    },
    REPASSEMEDICOCADASTRO: {
        MANUTENCAO: "Manutenção",
        REPASSEMEDICO: "Repasse Médico",
        NOME: "Nome",
        VALORMINIMO: "Valor Mínimo",
        PORCENTAGEM: "Porcentagem",
        MEDICO: "Médico",
        CONVENIO: "Convênio",
        SETOR: "Setor",
        TABELADEEXAMES: "Tabela de Exames",
        ERROSALVAR: "Erro ao salvar "
    },
    REPASSEMEDICOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        TIPO: "Tipo",
        VALORMINIMO: "Valor Mínimo",
        PORCENTAGEM: "Porcentagem",
        MEDICO: "Médico",
        CONVENIO: "Convênio",
        SETOR: "Setor",
        TABELADEEXAMES: "Tabela de Exames",
    },
    UNIDADELISTA: {
        PROCURAR: "Procurar",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        UNIDADES: "Unidades",
    },
    UNIDADECADASTRO: {
        SALVANDOUNIDADE: "A unidade está sendo salva, este processo pode levar alguns minutos.",
        NOME: "Nome",
        HORAPADRAO: "Hora Padrão de Retorno",
        FIMEXP: "Hora de Fim do Expediente",
        MODELORES: "Modelo de Resultado",
        MODELOSEL: "Selecione o Modelo",
        DESATIVADO: "Desativado",
        CODINTEG: "Códigos integrações",
        CODIGO: "Código",
        TIPO: "Tipo",
        INTEG: "Integrador",
        ADDCODIGO: "Adicionar código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        UNIDADE: "Unidade",
        CONFIGNF: "Configurações NF",
        CAMPOSNF: "Campos NF",
        VALORESNF: "Valores",
        ADDCONFIG: "Adicionar configuração"
    },
    LOCAISLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        LOCAIS: "Locais",
    },
    LOCALCADASTRO: {
        NOME: "Nome",
        MODELORESULTADO: "Modelo de Resultado",
        SELECIONEMODELO: "Selecione um Modelo",
        EMAIL: "Email",
        TIPOLOCAL: "Tipo de Local",
        SELTIPOLOCAL: "Selecione um tipo de Local",
        ENDERECO: "Endereço",
        URGENCIAMIN: "Urgencia minutos",
        FUNC: "Funcionario",
        UNIDADE: "Unidade",
        PUBINTERNET: "Publica resultados na internet?",
        PACIENTESEMRES: "Pacientes não tem acesso aos resultados",
        URGENTE: "Urgente",
        DESATIVADO: "Desativado",
        USUARIO: "Usuário",
        USUARIOWEB: "Usuário web",
        CODINTEG: "Códigos integrações",
        CODIGO: "Codigo",
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        ADDCOD: "Adicionar código",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        LOCAL: "Local",
        EMAILVAZIO: "O campo e-mail precisa ser preenchido",
        ERROSALVAR: "Erro ao salvar guia Local",
        OEMAIL: "O e-mail ",
        EMUSO: " já está sendo usado pelo usuario do ",
        DESATIVARUSUARIO: "Desativar usuário Web",
        ERROEXCLUIR: "Erro ao excluir usuario: ",
    },
    TIPOLOCAISLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        ACIDENTE: "Acidente ocupacional",
        COLETADOM: "Coleta domiciliar",
        TIPOLOCAL: "Tipos de locais",
    },
    TIPOLOCALCADASTRO: {
        NOME: "Nome",
        ACIDENTE: "Acidente ocupacional",
        COLETADOM: "Coleta domiciliar",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        TIPOLOCAL: "Tipo de local",
    },
    TIPOAGENDAMENTOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        TIPOAGENDAMENTO: "Tipos de agendamento",
    },
    TIPOAGENDAMENTOCADASTRO: {
        SALVANDOAGENDAMENTO: "O tipo de agendamento está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        COR: "Cor",
        INTERVALO: "Intervalo Duração (em minutos)",
        UNIDADE: "Unidade",
        DIASREALIZACAO: "Dias de realização",
        DOMINGO: "Domingo",
        SEGUNDA: "Segunda",
        TERCA: "Terça",
        QUARTA: "Quarta",
        QUINTA: "Quinta",
        SEXTA: "Sexta",
        SABADO: "Sábado",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        TIPOAGENDAMENTO: "Tipo de agendamento",
        ESCALA: "Escalas",
        IDESCALA: "Id",
        DIADASEMANA: "Dia da Semana",
        NOMEESCALA: "Nome",
        HORAINICIO: "Hora Inicio Expediente",
        HORAFIM: "Hora Fim Expediente",
        INCLUIR: "Incluir",        
        ATENCAO: "Atenção",        
        CAMPOSOBRIGATORIOS: "Preencha todas os campos!",        
        EQUIPAMENTOS: "Equipamentos", 
        SEXO: "Sexo",
        SELECIONARSEXO: "Restricao ao sexo?",
        FEM: "Feminino",
        MASC: "Masculino",
        TODOS: "Todos",
        IDADEMIN: "Idade Min.",
        IDADEMAX: "Idade Max.",
        LIMITEPESO: "Limite de Peso (kg)",

    },
    PESQUISASLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        ID: "Id",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        PESQUISA: "Pesquisas",
    },
    PESQUISACADASTRO: {
        NOME: "Nome",
        DESTINO: "Destino Pesquisa",
        NENHUM: "Nenhum",
        LOCAL: "Local",
        RECEPCAO: "Recepção",
        COLETA: "Coleta",
        OBGT: "Obrigatório",
        COMPARTILHARGUIAS: "Compartilhar resposta com outras guias",
        COMPARTILHAREXAMES: "Compartilhar resposta com outros exames",
        PERGUNTAS: "Perguntas",
        RESPOSTAS: "Respostas",
        LIVRE: "Livre?",
        DATA: "Data?",
        ARQV: "Arquivo?",
        NUM: "Numerico?",
        INCLUIRRESP: "Incluir Resposta",
        INCLUIRPERGUNTA: "Incluir Pergunta",
        CODINTEG: "Códigos integrações Pesquisa",
        TIPO: "Tipo",
        CODIGO: "Código",
        INTEG: "Integrador",
        ADDCODIGO: "Adicionar código",
        CODINTEGPEG: "Códigos integrações Pergunta",
        CODINTEGRES: "Códigos integrações Respostas",
        PEGITEM: "Pergunta Item",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        PESQUISA: "Pesquisa",
        ERROSALVAR: "Erro ao salvar pesquisa: ",
        TRIAGEM: "Triagem"
    },
    FERIADOSLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        FERIADOS: "Feriados",
    },
    FERIADOCADASTRO: {
        SALVANDOCADASTRO: "O feriado está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        DATA: "Data",
        ANO: "Ano",
        FERIADOSNAC: "Importar Feriados Nacionais",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        FERIADO: "Feriado",
        JAEXISTE: "Ja existe um feriado com a mesma data ja cadastrada",
    },
    CONDICOESLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        DESC: "Descrição",
        ID: "Id",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        CONDPAG: "Condições de Pagamentos",
    },
    CONDICOESCADASTRO: {
        SALVANDOCADASTRO: "A condição de pagamento de exame está sendo salva, este processo pode levar alguns minutos.",
        DESC: "Descrição",
        ESPECIEPAG: "Espécie de Pagamento",
        SELESPECIEPAG: "Selecione a Espécie",
        QTDEPARCEL: "Qtde. Parcelas",
        BANDEIRA: "Bandeira",
        SELBANDEIRA: "Selecione a Bandeira",
        VENCIMENTO: "Prazo de Vencimento",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONDPAG: "Condição de Pagamento",
    },
    ESPECIEPAGAMENTOLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        ESPECIEPAG: "Especie de pagamento",
    },
    ESPECIEPAGAMENTOCADASTRO: {
        SALVANDOCADASTRO: "A especie de pagamento está sendo salva, este processo pode levar alguns segundos.",
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        ESPECIEPAG: "Especie de pagamento",
    },
    SOROTECALISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        GELADEIRAS: "Geladeiras",
    },
    SOROTECACADASTRO: {
        MANUTENCAO: "Manutenção",
        GELADEIRAS: "Geladeiras",
        SALVANDOCADASTRO: "A geladeira está sendo salva, este processo pode levar alguns minutos.",
        NOME: "Nome",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    SOROTECACONTAINERLISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        CONTAINERS: "Containers",
    },
    SOROTECACONTAINERCADASTRO: {
        SALVARCADASTRO: "O container está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        POSICAO: "Posições:",
        QTDECOLUN: "Quantidade de colunas:",
        QTDELINHAS: "Quantidade de linhas:",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONTAINERS: "Soroteca - Container",
    },
    CONFIGTISSLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        NOME: "Nome",
        ID: "Id",
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        CONFIGTISS: "Configuração TISS",
    },
    CONFIGTISSCADASTRO: {
        NOME: "Nome",
        ANS: "Registro ANS",
        LOG: "Logradouro",
        COMPLEMENTO: "Complemento",
        MUN: "Municipio",
        MUNIBGE: "Municipio IBGE",
        UF: "UF",
        CNPJ: "CNPJ",
        CNES: "CNES",
        ULTIMOSEQ: "Ultimo Sequencial",
        ULTIMOLOTE: "Ultimo Lote",
        UNIDADES: "Unidades",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        CONFIGTISS: "Configuração TISS",
        ERROSALVAR: "Erro ao salvar configuração TISS",
    },
    WORKLIST:{
        AGENDAMENTOEXAMES:"Agendamento de Exames",
        PACIENTE:"Paciente",
        CONVENIO:"Convênio",
        EXAME:"Exame",
        AGENDA:"Agenda",
        AGENDAMENTOREALIZADO:"Agendamento Realizado",
        SALVAR:"Salvar",
        ATENDIMENTO:"Atendimento",
        WORKLIST:"Worklist",        
        ENCAIXE:"Encaixe",
        CHECKIN:"Enviar para Sala de Espera?",
        AGENDADOEM:"Agendado",
        EXECUTANTE:"Executante",
        CONFIRMARCHECKIN:"O paciente está presente na clínica, deseja enviar para sala de espera?",
        ATENCAO:"Atenção",
        EXISTEMEXAMESPENDENTES:"Existem Exames que ainda não foram agendados!",
        REENVIARWORKLIST: "Re-Enviar para Sala de Espera?",
        SELECIONEAGENDA: "Selecione a Agenda",
        SELECIONEEXECUTANTE: "Selecione o Executante",
        ALTERARAGENDA: "Alterar Agenda",
        CANCELARAGENDAMENTO: "Cancelar Agendamento?",
        REALIZARENCAIXE: "Selecione a data e hora para o encaixe",
    },
    AGENDAMENTOTAREFA: {
        COMENTARIO: "Comentários",
        COMANDO: "Comando",
        MIN: "Minutos",
        HORAS: "Horas",
        DIA: "Dia",
        MES: "Mês",
        DIASEMANA: "Dia da Semana",
        CONFIG: "Configurações",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Manutenção",
        AGENDATAREFA: "Agendamento de Tarefa",
        NAOPERMITIDO: "Comando não permitido.",
        ERROSALVAR: "Erro ao salvar agendamento: ",
    },
    SOBRE: {
       APRESENTACAO: "Sistema de informação para laboratórios de análises clínicas",
       LICENCIADOPARA: "Este sistema está licenciado para:",
       VERSAO: "versão" 
    },
    SIDEBAR: {
        ATENDIMENTO: "Atendimento",
        ANALISE: "Análise",
        EQUIPAMENTO: "Equipamento",
        INTEGRACAO: "Integração",
        MANUTENCAO: "Manutenção",
        ESTOQUE: "Estoque",
        FINANCEIRO: "Financeiro",
        RELATORIO: "Relatório",
        PLUGINS: "Plugins",
        SAIR: "Sair"
    },
    MODALNOTIFICACAO: {
        TITULO: "Nenhuma Notificação | 1 Notificação | {n} Notificações",
        FECHAR: "Fechar",
        APAGARTODAS: "Apagar Todas",
        APAGAR: "Apagar esta notificação",
        NOTIFICARGUIACOMPLETA: "Notificar Guia Completa"
    },
    ESTOQUE:{
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        PROCURAR: "Procurar",
        TITULO: "Estoque"
    },
    ESTOQUESLISTA:{
        TITULO: "Estoque",
        TITULOESTOQUE: "Cadastro de Estoques",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    ESTOQUECADASTRO: {
        NOME: "Nome",
        TITULO: "Cadastro",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        SALVANDO: "Salvando...",
        UNIDADE: "Unidade",
        SELECIONEUNIDADE: "Selecione a Unidade"
    },
    UNIDADEDEMEDIDALISTA:{
        TITULO: "Unidades de Medida",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        FATOR: "Fator",
        UNIDADEREFERENCIA: "Unidade Referência",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    UNIDADEDEMEDIDACADASTRO:{
        TITULO: "Unidades de Medida",
        LISTARTODOS: "Listar Todos",
        NOME: "Nome",
        FATOR: "Fator",
        UNIDADEDEMEDIDADEREFERENCIA: "Unidade de Medida de Referência",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",     
        SELECIONEUNIDADE: "Selecione a Unidade de Medidade de Referência",
        SALVANDO: "Salvando...",
        CODIGOANS: "Código ANS",
    },
    CATEGORIALISTA:{
        TITULO: "Categoria",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    CATEGORIACADASTRO: {
        NOME: "Nome",
        TITULO: "Categoria",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        SALVANDO: "Salvando...",
        UNIDADE: "Unidades",
        SELECIONEUNIDADE: "Selecione a Unidade"
    },
    MARCASLISTA:{
        TITULO: "Cadastro de Marcas",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    MARCASCADASTRO: {
        NOME: "Nome",
        TITULO: "Cadastro de Marcas",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        SALVANDO: "Salvando..."
    },
    CONSUMIVEISLISTA:{
        TITULO: "Consumíveis",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        CATEGORIA: "Categoria",
        MARCA: "Marca",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    CONSUMIVELCADASTRO: {

        NOVAMARCA: "Nova marca",
        NOVACATEGORIA : "Nova categoria",
        NOVAUNIDADE: "Nova unidade de medida",
        NOME: "Nome",
        ESTOQUECONTROLADO: "Estoque Controlado",
        SERVICO: "Serviço",
        REGISTROMS: "Registro no Ministério da Saúde",
        CODIGODEBARRAS: "Código de Barras",
        CATEGORIA: "Categoria",
        MARCA: "Marca",
        UNIDADESDEMEDIDA: "Unidade de Medida",
        QUANTIDADEMINIMA: "Quantidade Mínima",
        OBSERVACOES: "Observações",
        TITULO: "Cadastro Consumível",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        SALVANDO: "Salvando..."
    },
    LOTESLISTA:{
        TITULO: "Lotes",
        LISTARTODOS: "Listar Todos",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        NOME: "Nome",
        LOTE: "Lote",
        CONSUMIVEL: "Consumível",
        VALIDADE: "Validade",
        EDITAR: "Editar",
        PROXPAGINA: "Próxima Página",
        PAGANTERIOR: "Próxima Anterior",
        PAGINA: "Página",
        PAGATUAL: "Página Atual",
        PROCURAR: "Procurar"
    },
    LOTECADASTRO: {
        NOME: "Nome",
        CONSUMIVEL: "Consumível",
        DIASAVISO: "Dias de Aviso",
        VALIDADE: "Validade",
        TITULO: "Cadastro Lote",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        ATENCAO: "Atenção!",
        CAMPOSOBRIGATORIOS: "Preencha os Campos Obrigatórios.",
        SALVANDO: "Salvando..."
    },
    BUSCAEQUIPAMENTO: {
        ID: "Id",
        EQUIPAMENTO: "Equipamento",
        INCLUIR: "Incluir",
    },
    ESTUDOLAUDO:{
        BUSCARLAUDO: "Modelo de Laudo",
        TITULOMODELOLAUDO: "Modelo de Laudo obrigatório",
        MESANGEMMODELOLAUDO: "Especifique ou selecione um modelo de laudo para continuar.",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        EHVALIDO: "Validado?",
        LAUDOESTRUTURADO: "Laudo Estruturado",
    },
    ESTUDOSSEMVINCULO: {
        TITULOVINCULAR: "Vincular guia",
        CONFIRMAVINCULAR: "Confirma o vínculo dos resultados com a guia e o exame ?",
        CODIGO: "Código da guia",
        EXAME: "Posição do exame na guia",
        CANCELAR: "Cancelar",
    },
    ESTUDO:{
        GUIA: "Guia:",
        ACCESSIONNUMBER: "AccessionNumber:",
        MOSTRAPACIENTESEXOIDADE: "Paciente, sexo, idade:",
        MOSTRARACAESPECIE: "Raça, espécie:",
        CRM: "CRM:",
        SERIES: " séries",
        IMAGENS: " imagens",
        ALERTADESVINCULARESTUDO: "Somente exames não laudados podem ser desvinculados",
    },
    ESTUDOS:{
        PLACEHOLDERPROCURARPACIENTE: "Procurar por nome",
        ACCESSIONNUMBER: "Número de Acesso",
        PLACEHOLDERPROCURARACCESSIONUMBER: "Procurar por número",
        SETOR: "Setor",
        DEPARTAMENTO: "Departamento",
        UNIDADE: "Unidade",
        EXECUTANTE: "Executante",
        FILTRAPERIODO: "Período",
        FILTRAPORSOLICITACAO: "Solicitação",
        FILTRAPORESTUDORECEBIDO: "Estudo Recebido",
        FILTRAPORRETORNO: "Retorno",
        URGENTE: "Urgente",
        FILTRARSTATUS: "Filtrar por status",
        FILTRARTODOS: "Todos",
        SELECIONARCONVENIOS: "Selecione o(s) convênio(s)",
        SELECIONARSETORES: "Selecione o(s) setor(es)",
        SELECIONARDEPARTAMENTOS: "Selecione o(s) departamento(s)",
        SELECIONARUNIDADES: "Selecione a(s) unidade(s)",
    },
    MODALRETIFICALAUDO: {
        RETIFICACAO: "Retificação de resultado",
        NOTIFICAÇÃO: "Este resultado já estava assinado. Por favor, informe o motivo da retificação",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar retificação",
        CANCELAR: "Cancelar",
    },
    PAINELATENDIMENTO: {
        SENHAATENDIMENTO: "Senha de atendimento",
        GUICHE: "Guichê",
        ULTIMASCHAMADAS: "Últimas chamadas",
    },
    TOTENATENDIMENTO: {
        OPCAODESEJADA: "Selecione a opção desejada.",
    },
    MODALFILAATENDIMENTO: {
        SENHAATENDIMENTO: "Senha de atendimento",
        UNIDADE: "Unidade",
        GUICHE: "Guichê",
        SITUACAO: "Situação",
        TODOS: "Todos",
        RECEPCAO: "Recepção",
        COLETA: "Coleta",
        TIPO: "Tipo",
        SEQUENCIA: "Sequência",
        CHAMAR: "Chamar",
        ATENDER: "Atender",
        CANCELAR: "Cancelar",
        TEMPOESPERA: "Tempo de espera",
        FECHAR: "Fechar",
    },
    MODELOLAUDODESCRITIVO: {
        MANUTENCAO: "Manutenção",
        MODELOLAUDODESCRITIVO: "Modelo laudo descritivo",
        NOME: "Nome",
        SETOR: "Setor",
        UNIDADE: "Unidade",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
    },
    MODELOLAUDODESCRITIVOLISTA: {
        PROCURAR: "Procurar por nome",
        INCLUIR: "Incluir",
        CODIGO: "Código",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual",
        ID: "Id",
        DESCRICAO: "Nome",        
        EDITAR: "Editar",
        MANUTENCAO: "Manutenção",
        MODELOLAUDODESCRITIVO: "Modelo laudo descritivo",
    },    
    FATURAS: {
        FATURA: "Fatura",
        DATA: "Data",
        SITUACAO: "Situação",
        PDF: "PDF Padrão",
        PDFSINTETICO: "PDF Sintético",
    },
    MODALANEXO: {
        ANEXAR: "Anexar um estudo",
        ARRASTEARQUIVOS: "Arraste os arquivos aqui ou clique para selecioná-los",
        FECHAR: "Fechar"
    },
    MODALREMOVERVINCULO: {
        REMOVER: "Remover vinculo",
        NOTIFICAÇÃO: "Por favor, informe o motivo da desvinculação",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARDESVINCULAR: "Confirmar desvinculação",
        CANCELAR: "Cancelar",
    },
    STATUSAMOSTRA: {
        NOME: 'Nome',
        COR: 'Cor',
        ORDEM: 'Ordem',
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        STATUS: "Status amostra",
        SALVANDOAGENDAMENTO: "O status de amostra está sendo salvo, este processo pode levar alguns minutos.",
        MANUTENCAO: "Manutenção",
    },
    STATUSAMOSTRALISTA: {
        PROCURAR: "Procurar",
        INCLUIR: "Incluir",
        ID: "Id",
        PROXPAGINA: "Próxima página",
        PAGINAANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGINAATUAL: "Página atual",
        NOME: "Nome",
        ORDEM: "Ordem",
        EDITAR: "Editar",
    }
}